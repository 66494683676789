import gql from 'graphql-tag';
// Get all programs
export const GET_EQUIDAM_OPTIONS = gql`
query get_equidam {
    equidam {
        industries
        countries
    }
}
`;

export const GET_VENTURE_EQUIDAM_TENQ = gql`
  query get_venture($id: ID!, $idType: String) {
    venture(id: $id, idType: $idType) {
        id
        venture_id
        equidam_cid
        equidam_tenq
        equidam_tenq_ts
        equidam_valuation
    }
    equidam {
        industries
        countries
    }
}
`;

export const CREATE_VENTURE_EQUIDAM_CID = gql`
mutation create_venture_equidam_cid($input: CreateVentureCidInput!) {
    createVentureCid(input: $input) {
        status
        equidam_cid
    }
}
`;

export const UPDATE_VENTURE_EQUIDAM_TENQ = gql`
mutation update_venture_equidam_tenq($input: UpdateVentureTenqInput!) {
    updateVentureTenq(input: $input) {
        status
        venture_id
        equidam_cid
        equidam_tenq
        equidam_valuation
    }
}
`;
