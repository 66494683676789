<template>
    <div class="star-rating">
        <vc4a-icon
            v-for="(item, index) in Math.floor(rating)"
            :key="id + '-full-star-' + index "
            name="star"
            :color="activeColor"
        />
        
        <!-- Part-filled stars (For rating decimals) -->
        <svg
            v-if="getRatingDecimal"
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24" height="24"
        >
            <defs>
                <linearGradient :id="id + '-gradient'" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" :stop-color="activeColor"></stop>
                    <stop :offset="( getRatingDecimal * 100) + '%'" :stop-color="activeColor"></stop>
                    <stop :offset="( getRatingDecimal * 100) + '%'" :stop-color="inactiveColor"></stop>
                    <stop offset="100%" :stop-color="inactiveColor"></stop>
                </linearGradient>
            </defs>
            <path 
                :fill="'url(#' + id + '-gradient' + ')'"
                d="M17.56 21a1 1 0 0 1-.46-.11L12 18.22l-5.1 2.67a1 1 0 0 1-1.45-1.06l1-5.63-4.12-4a1 1 0 0 1-.25-1 1 1 0 0 1 .81-.68l5.7-.83 2.51-5.13a1 1 0 0 1 1.8 0l2.54 5.12 5.7.83a1 1 0 0 1 .81.68 1 1 0 0 1-.25 1l-4.12 4 1 5.63a1 1 0 0 1-.4 1 1 1 0 0 1-.62.18z"
                class="star-rating__star--partial"
                :stroke="activeColor"
            />
            <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
        </svg>

        <!-- Inactive stars (Greyed out stars)-->
        <vc4a-icon
            v-for="(item, index) in ( Math.floor(total - rating) )"
            :key="id + '-inactive-star-' + index "
            name="star"
            :color="inactiveColor"
            :stroke="activeColor"
        />
    </div>
</template>

<script>

import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "StarRating",
    components: {
        Vc4aIcon
    },
    props: {
        rating: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            default: 5
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            activeColor: '#F1C644',
            inactiveColor: 'transparent'
        }
    },
    computed: {
        getRatingDecimal() {
            return this.rating - Math.floor(this.rating)
        }
    }
}
</script>
