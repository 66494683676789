<!-- 
    Example of use-case:

    Put whatever markdown and styling you want in the content.
    Just make sure to leave space on the right for the chevron.

     <vc4a-collapse>
        <div #base>View content by clicking here</div> // clickable item
        <div #content>[this is the content that was hidden]</div>
     </vc4a-collapse>
 -->

<template>
    <div class="vc4a-collapse">
        <!-- Toggle button -->
        <div
            @click.prevent="toggleCollapse"
            class="vc4a-collapse__base"
            :aria-expanded="JSON.stringify(contentIsVisible)" 
            :aria-controls="`vc4a-collapse--${_uid}`"
        >
            <slot name="base"/>
            <vc4a-icon
                class="vc4a-collapse__chevron"
                :class="chevronDynamicClassStr"
                name="chevron-down"
            />
        </div>

        <!-- Programmatically resizing container for content. Needed for good animation -->
        <div
            class="vc4a-collapse__content"
            :style="{ height: contentDynamicHeight + 'px' }"
        >
            <!-- This is the part that actually moves. -->
            <div 
                class="vc4a-collapse__content-inner"
                :class="contentDynamicClassStr"
                ref="vc4a-collapse-content-inner"
                :id="`vc4a-collapse--${_uid}`"
            >
                <slot name="content"/>
            </div>
        </div>    
    </div>
</template>

<script>
import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "PopupMenu",
    components: {
        Vc4aIcon
    },
    data(){
        return {
            contentIsVisible: false,
            chevronDynamicClassStr: '',
            contentDynamicClassStr: '',
            contentDynamicHeight: 0,
        }
    },
    methods: {
        toggleCollapse() {
            if(this.contentIsVisible === false) {
                this.showContent();
                
                return;
            }
            
            this.hideContent();
        },
        hideContent() {
            this.contentIsVisible = false;

            // Dynamic values needed for open/close animation
            this.chevronDynamicClassStr = '';
            this.contentDynamicClassStr = '';
            this.contentDynamicHeight = 0;
        },
        showContent() {
            this.contentIsVisible = true;

            const contentInnerElement = this.$refs['vc4a-collapse-content-inner'];
            
            // Dynamic values needed for open/close animation
            this.chevronDynamicClassStr = 'vc4a-collapse__chevron--open';
            this.contentDynamicClassStr = 'vc4a-collapse__content-inner--open';
            this.contentDynamicHeight = contentInnerElement.clientHeight;
        }
    },
}
</script>
