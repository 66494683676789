<template>
    <div class="flash" :class="getType" >{{ message }}</div>
</template>

<script>
export default {
    name: "flash",
    props: {
        message: String,
        type: String
    },
    computed: {
        getType() {
            switch (this.type) {
                case 'danger':
                    return 'flash--danger';
                case 'success':
                    return 'flash--success';
                case 'warning':
                    return 'flash--warning';
                default:
                    return 'flash--info';
            }
        }
    }
}
</script>