<template>
    <div>
        <label
            v-if="label"
            :for="id"
            :class="size === 'lg' ? 'vc4a-text-input__label-lg' : 'vc4a-text-input__label'"
        >
            {{ label }}
        </label>
        <input
            :type="type"
            :id="id"
            :name="name"
            :min="min"
            :max="max"
            :maxlength="maxlength"
            class="vc4a-text-input"
            :class="[{
                'vc4a-text-input--sm': size === 'sm',
                'vc4a-text-input--lg': size === 'lg',
                'vc4a-text-input--invalid': !!errorText,
            }]"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="modelValue"
            @focus="$emit( 'focus', $event )"
            @blur="$emit( 'blur', $event )"
            @input="$emit( 'update:modelValue', $event.target.value )"
            :required="required ? true : null"
            :readonly="readonly ? true : null"
            :aria-required="required ? true : null"
            :aria-invalid="errorText ? true : null"
            :aria-describedby="errorText ? errorHintId : null"
        />
        <div
            v-if="errorText"
            :id="errorHintId || null"
            class="vc4a-text-input__error"
            :role="errorText ? 'alert' : null"
        >
            {{ errorText }}
        </div>
        <div
            v-else-if="helperText"
            class="vc4a-text-input__helper"
        >
            {{ helperText }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Vc4a-Text-Input',
    props: {
        type: {
            type: String,
            default: 'text'
        },
        id: {
            type: String,
            //randomly generates a key from 0 to 10,000
            default: ()=> {
                return 'input-' + Math.floor(Math.random() * 10000)
            }
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {
            type: String
        },
        size: {
            type: String,
        },
        min: {
            type: Number,
        },
        max: {
            type: Number,
        },
        maxlength: {
            type: Number,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorText: {
            type: String
        },
        helperText: {
            type: String
        },
        modelValue: {
            type: [ String, Number ] // Adds support for v-model
        },
        required: {
            type: Boolean
        },
        readonly: {
            type: Boolean
        }
    },
    emits: [ 'update:modelValue', 'focus', 'blur' ],
    methods: {
        errorHintId: () =>  {
            return this.$props.id + '-error';
        }
    }
};
</script>
