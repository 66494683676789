import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { createHttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();

const getUri = () => {
    let url = new URL( window.location );
    let params = new URLSearchParams(url.search);
    let lang = params.get('lang');

    return ( process.env.VUE_APP_GRAPHQL_ENDPOINT || 'http://vc4a.local/graphql?XDEBUG_SESSION_START=MY_AWESOME_KEY' ) + ( lang ? '?lang=' + lang : '' );
}

// Setup apollo graphQl client for English endpoint.
const httpLink = new createHttpLink( {
    uri: getUri(),
    credentials: 'same-origin',
} );

export const apolloClient = new ApolloClient( {
    link: httpLink,
    cache: cache,
    connectToDevTools: true,
} );

export const apolloProvider = createApolloProvider( {
    defaultClient: apolloClient,
    defaultOptions: {
        $loadingKey: 'loading'
    },
} );
