import gql from 'graphql-tag';

// Get term data
export const GET_TERM = gql`
query get_term($id: ID!, $idType: String) {
    term(id: $id, idType: $idType) {
        id
        term_id
        name
        slug
        name__fr_FR
        name__es_ES
    }
}
`;

// Get all available sector terms
export const GET_TERMS = gql`
query get_terms($first: Int, $after: String, $last: Int, $before: String, $where: ConnectionTermsWhereArgs) {
    terms(first: $first, after: $after, before: $before, last: $last, where: $where) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
        }
        edges {
            cursor
            node {
                id
                term_id
                name
                slug
                name__fr_FR
                name__es_ES
            }
        }
    }
}
`;

// Get all available hierarchical terms.
export const GET_HIERARCHICAL_TERMS = gql`
query get_terms($first: Int, $after: String, $last: Int, $before: String, $where: ConnectionTermsWhereArgs) {
    terms(first: $first, after: $after, before: $before, last: $last, where: $where) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
        }
        edges {
            cursor
            node {
                id
                term_id
                name
                slug
                name__fr_FR
                name__es_ES
                
                childItems {
                    nodes {
                        id
                        term_id
                        name
                        slug
                        name__fr_FR
                        name__es_ES
                        
                        childItems {
                            nodes {
                                id
                                term_id
                                name
                                slug
                                name__fr_FR
                                name__es_ES
                            }
                        }
                    }
                }
            }
        }
    }
}
`;