import { createApp } from 'vue';

import { apolloProvider } from 'src/graphql/apollo-client';

import i18n from './i18n';

// Sub-templates from Mentors repo (`src` is aliased in vue.config.js)
import MentorFilter from 'src/views/forms/mentor-filter';
import MentorHeroActions from 'src/views/forms/mentor-hero-actions';

// Sub-templates from parent theme
import YoutubeEmbed from '$globalsrc/common/youtube-embed';
import ModalBasic from '$globalsrc/modal/modal-basic';
import Vc4aCarousel from '$globalsrc/common/vc4a-carousel';
import NavTabs from '$globalsrc/common/nav-tabs';
import PopupMenu from '$globalsrc/common/popup-menu';
import EmptyState from '$globalsrc/common/empty-state';
import StarRating from '$globalsrc/common/star-rating';
import Vc4aCollapse from '$globalsrc/common/vc4a-collapse';
import Vc4aIcon from '$globalsrc/common/vc4a-icon';

// Dedicated components
import BtnRemoveFromCohort from '$globalsrc/cohorts/buttons/btn-remove-from-cohort';
import CohortSettingsStatus from '$globalsrc/cohorts/cohort-settings-status';
import CohortSettingsEmail from '$globalsrc/cohorts/cohort-settings-email';
import CohortEmailMember from '$globalsrc/cohorts/modals/cohort-email-member';

// Equidam
import BusinessValuationCard from '@/views/equidam/business-valuation-card.vue';

// This imports all common, loader components and directives globally.
import { ClickOutside } from "src/directives/ClickOutside";
import Alert from 'src/views/common/alert';
import Flash from 'src/views/common/flash';
import Icon from 'src/views/common/icon';
import Modal from 'src/views/common/modal';
import Tag from 'src/views/common/tag';
import Vc4aSelect from "src/views/utility/vc4a-select.vue";
import LoaderCluster from "src/views/loaders/loader-cluster";
import LoaderClusterItem from "src/views/loaders/loader-cluster-item";

let vc4aApps = document.getElementsByClassName('vc4a-theme-app');
let apps = [];
if( vc4aApps.length ) {
    for( let i=0; i<vc4aApps.length; i++ ) {
        let vc4aApp = vc4aApps[i];
        apps[i] = createApp({
            apolloProvider,
            components: {
                MentorFilter,
                MentorHeroActions,
                YoutubeEmbed,
                Vc4aCarousel,
                PopupMenu,
                BtnRemoveFromCohort,
                CohortSettingsStatus,
                CohortSettingsEmail,
                CohortEmailMember,
                EmptyState,
                StarRating,
                Vc4aCollapse,
                Vc4aIcon,
                BusinessValuationCard
            }
        })
            .use(i18n)
            .use(apolloProvider)

            //Common Components and loaders
            .component('Alert', Alert)
            .component('Flash', Flash)
            .component('Icon', Icon)
            .component('Modal', Modal)
            .component('ModalBasic', ModalBasic)
            .component('NavTabs', NavTabs)
            .component('Tag', Tag)
            .component('LoaderCluster', LoaderCluster)
            .component('LoaderClusterItem', LoaderClusterItem)
            .component('Vc4aSelect', Vc4aSelect)


            // Vue directives
            .directive('click-outside', ClickOutside);

        apps[i].mount('#' + vc4aApp.id);
    }
}
