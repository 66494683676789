<!-- 
    Basic component: radio input

    TODO: 
        I'm wondering if I should make this into a multi-component component,
        as you'll never get a single radio, and this component is the same size as the DOM value it renders
        However I do like it as it keeps the dom structure consistent (which the custom scss requires)

    <vc4a-radio
        name="my_name"
        value="a_million_bucks"
        :bindTo="v_model_equivalent"
        labelStr="One million dollars"
        @radioValueChanged="(event) => enableReminderEmails = event.target.value" 
        ref="foobar"
    />    
-->

 <template>
    <label
        class="vc4a-radio"
    >
        <input
            type="radio"
            :name="name"
            :value="value"
            :checked="vmodelVal === value"
            @change="handleChange"
        >
        <div class="vc4a-radio__radio"></div>
        {{ labelStr }}
    </label>
</template>

<script>

export default {
    name: "Vc4aRadio", 
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [ Number, String ],
            required: true,
        },
        bindTo: {
            type: [ Number, String ],
            required: true,
        },
        labelStr: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            vmodelVal: this.bindTo,
        }
    },
    methods: {
        handleChange(value) {
            this.$emit('radioValueChanged', value);
        }
    },
}
</script>
