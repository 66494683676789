<template>
    <div class="flex flex-col gap-1">
        <!-- 'Active' choice -->
        <div class="radio-panel form-radio">
            <input type="radio" id="cohort_status_active" name="cohort_is_active_status" value="active" v-model="chosenState"/>
            <label for="cohort_status_active" class="mb-0">
                <div class="radio-panel__radio"></div>
                <div>
                    <div class="radio-panel__option-title">
                        <!-- "Active" -->{{ $t('cohorts.settings.cohort_status.active_state.title') }}
                    </div>
                    <p class="paragraph-sm text-secondary mb-0">
                        <!-- "This option enables cohort visibility[...]" -->{{ $t('cohorts.settings.cohort_status.active_state.text') }}
                    </p>
                </div>
            </label>
        </div>

        <!-- 'Inactive' choice -->
        <div class="radio-panel form-radio">
            <input type="radio" id="cohort_status_inactive" name="cohort_is_active_status" value="inactive" v-model="chosenState"/>
            <label for="cohort_status_inactive" class="mb-0">
                <div class="radio-panel__radio"></div>
                <div>
                    <div class="radio-panel__option-title">
                        <!-- "Inactive" -->{{ $t('cohorts.settings.cohort_status.inactive_state.title') }}
                    </div>
                    <p class="paragraph-sm text-secondary mb-0">
                        <!-- "This option disables cohort visibility[...]" -->{{ $t('cohorts.settings.cohort_status.inactive_state.text') }} </p>
                </div>
            </label>
        </div>

        <!-- Confirmation modal (and btn) -->
        <modal-basic max-width="32rem" :show-on-load="false" ref="cohort-settings-confirm-modal">
            <template #title>{{ modalTextObj.titleStr }}</template>

            <!-- Content -->
                <p>{{ modalTextObj.textStr }}</p>
                <div class="flex gap-1 justify-end">
                    <div class="btn btn--secondary" @click="handleCancelBtn()">
                        <!-- "Cancel" -->{{ $t('common.ctas.cancel') }}
                    </div>
                    <div class="btn btn--primary" @click="handleClickSubmit()">
                        {{ modalTextObj.confirmCtaStr }}
                        <icon
                            name="spinner"
                            class="loader-spinner"
                            v-if="queryInProgress"
                        />
                    </div>
                </div>

            <template #toggle-btn>
                <button class="btn btn--primary">
                    <!-- "Save changes" -->{{ $t('common.ctas.save_changes') }}
                </button>
            </template>
        </modal-basic>

        <!-- Success notification -->
        <vc4a-flash v-if="showSuccessFlash" type="success" :message="$t('common.success.changes')"/>
        <!-- Fail notification -->
        <vc4a-flash v-if="showFailureFlash" type="danger" :message="$t('common.errors.basic')"/>
    </div>
</template>

<script>

import i18n from "@/i18n";

import ModalBasic from '$globalsrc/modal/modal-basic';
import Vc4aFlash from '$globalsrc/common/vc4a-flash';

export default {
    name: "CohortSettingsStatus",
    components: {
        ModalBasic,
        Vc4aFlash
    },
    props: {
        nonce: {
            type: String,
            required: true
        },
        cohortId: {
            type: Number,
            required: true
        },
        cohortIsActiveStatus: {
            type: Boolean,
            default: false,
        },
        sendReminderEmails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            chosenState: this.cohortIsActiveStatus ? 'active' : 'inactive',
            showSuccessFlash: false,
            showFailureFlash: false,
            queryInProgress: false,
            paramsObj: {
                "_nonce": this.nonce,
                "cohort_is_active_status": this.chosenState,
                "send_reminder_emails": this.sendReminderEmails,
                "cohort_id": this.cohortId
            }
        }
    },
    methods: {
        async handleClickSubmit() {
            this.queryInProgress = true;

            this.paramsObj = {
                "_nonce": this.nonce,
                "cohort_is_active_status": this.$data.chosenState,
                "send_reminder_emails": this.sendReminderEmails,
                "cohort_id": this.cohortId
            }

            await fetch(
                ajaxurl + '?action=cohort_settings&' + new URLSearchParams(this.paramsObj).toString(),
                {
                    method: 'POST',
                }
            ).then((response) => {
                if (response.status > 200) {
                    response.json().then((json) => {
                        this.queryInProgress = false;
                    });
                    return;
                }

                this.queryInProgress = false;
                this.showFlash('success');
            }).catch((error) => {
                console.error(error);
                this.showFlash('failure');
                this.queryInProgress = false;
            })

            this.$refs['cohort-settings-confirm-modal'].closeModal();
        },
        handleCancelBtn() { // needed to close modal from outside modal component
            this.$refs['cohort-settings-confirm-modal'].closeModal();
        },
        showFlash(type) {
            if (type === 'success') {
                this.showSuccessFlash = true;
            } else if (type === 'failure') {
                this.showFailureFlash = true;
            }

            setTimeout(() => {
                this.showSuccessFlash = false;
                this.showFailureFlash = false;
            }, '4000');
        },
    },
    computed: {
        modalTextObj() {
            if (this.chosenState === 'active') {
                return {
                    // "Confirm cohort visibility"
                    titleStr: i18n.global.t('cohorts.settings.cohort_status.confirm_active.title'),

                    // "By confirming, you are enabling cohort visibility[...]"
                    textStr: i18n.global.t('cohorts.settings.cohort_status.confirm_active.body'),

                    // "Enable visibility"
                    confirmCtaStr: i18n.global.t('cohorts.settings.cohort_status.confirm_active.cta'),
                }
            }

            return {
                // "Confirm cohort privacy"
                titleStr: i18n.global.t('cohorts.settings.cohort_status.confirm_inactive.title'),

                // "By confirming, you are disabling cohort visibility[...]"
                textStr: i18n.global.t('cohorts.settings.cohort_status.confirm_inactive.body'),

                // "Disable visibility"
                confirmCtaStr: i18n.global.t('cohorts.settings.cohort_status.confirm_inactive.cta'),
            }
        },
    },
}
</script>
