import en from "src/i18n/lang/en.js";
import es from 'src/i18n/lang/es.js';
import fr from 'src/i18n/lang/fr.js';
import { detectLang } from 'src/i18n/languageDetection';
import { createI18n } from 'vue-i18n';
import datetimeFormats from "src/i18n/datetimeFormats";

const i18n = createI18n({
    fallbackLocale: 'en',
    locale: detectLang(),
    globalInjection: true,
    legacy: false,
    messages: {
        en: en,
        es: es,
        fr: fr
    },
    datetimeFormats: datetimeFormats
})

export default i18n;
