<template>
    <div
        class="vc4a-progress-indicator"
    >
        <ul class="vc4a-progress-indicator__row list-unstyled">
            <li
                v-for="(sectionObj, index) in sectionsArr"
                class="vc4a-progress-indicator__item"
                :data-visual-state="visualStateStr(index)"
                :data-item-index="index"
                :data-clickable="allowClickableNav"
                @click="handleItemClick"
                :key="`indicator-item__${index}`"
            >
                <div class="vc4a-progress-indicator__stripe"></div>
                <div class="vc4a-progress-indicator__meta--desktop">
                    <div class="vc4a-progress-indicator__index">
                        <template v-if="visualStateStr(index) === 'complete'">
                            <check-circle-icon class="vc4a-icon--lg"></check-circle-icon>
                        </template>
                        <template v-else-if="visualStateStr(index) === 'error'">
                            <exclamation-circle-icon class="vc4a-icon--lg"></exclamation-circle-icon>
                        </template>
                        <template v-else>
                            {{ index + 1 }}
                        </template>
                    </div>
                    {{ sectionObj.titleStr }}
                </div>
            </li>
        </ul>
        <div class="vc4a-progress-indicator__meta--mobile">
            <!-- Commented out Step counter until we have vue3 and can install the translation library -->
            <!-- <div
                class="vc4a-progress-indicator__counter"
            >
            {{
                i18n.global.t(
                    'components.progress_indicactor.pagination',
                    { count: 3, total: 4 }
                )
            }}
                -- for_translation_file:
                        "matts_key" : "Step {count} of {total}"
                --
            </div> -->
            <h6>{{ sectionsArr[currentSection].titleStr }}</h6>
        </div>
    </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'Vc4aProgressIndicator',
    components: {
        CheckCircleIcon,
        ExclamationCircleIcon,
    },
    props: {
        currentSection: {
            type: Number,
            default: 0,
        },
        sectionsArr: {
            type: Object,
            default() {
                return []
            }
        },
        allowClickableNav: { // can you click the items to navigate pages?
            type: Boolean,
            default: false,
        }
    },
    emits: ['progressIndicatorUpdate'],
    methods: {
        visualStateStr(index) {
            if (index === this.currentSection) {
                return 'current';
            }

            return this.sectionsArr[index].status;
        },
        handleItemClick(event) {
            if (!this.allowClickableNav) {
                return;
            }

            const targetEl = event.target.closest('[data-item-index]');
            const targetElIndex = targetEl.dataset.itemIndex; //TODO:: Reintroduce optional chaining (temporarily removed because of Vue error)

            if (!targetElIndex) {
                return;
            }

            this.$emit('progressIndicatorUpdate', targetElIndex);
        }
    },
};
</script>
