<template>
    <div aria-hidden="true">
        
        <!--Edit Profile-->
        <template v-if="variant === 'edit-profile'">
            <div class="flex gap-1 items-center mb-7">
                <loader-cluster-item variant="avatar--lg"/>
                <loader-cluster-item variant="button"/>
            </div>
            <loader-cluster-item variant="line" class="mb-7"/>
            <div class="grid cols-2 gap-1 mb-7">
                <loader-cluster-item variant="line"/>
                <loader-cluster-item variant="line"/>
            </div>
            <div class="grid cols-2 gap-1 mb-7">
                <loader-cluster-item variant="line"/>
            </div>
            <loader-cluster-item variant="block" class="mb-7"/>
            <loader-cluster-item variant="button"/>
        </template>
        
        <!--Onboarding progress-->
        <template v-if="variant === 'onboarding-progress'">
            <loader-cluster-item variant="block" class="mb-5"/>
            <loader-cluster-item variant="short-line" class="mb-7"/>
        </template>
        
        <!--Mentor request-->
        <template v-if="variant === 'mentor-request'">
            <div class="flex gap-1 items-center mb-5">
                <loader-cluster-item variant="short-line"/>
                <div class="flex-grow">
                    <loader-cluster-item variant="line"/>
                </div>
                <loader-cluster-item variant="button"/>
            </div>
            <div class="flex gap-1 items-center mb-7">
                <loader-cluster-item variant="short-line"/>
                <div class="flex-grow">
                    <loader-cluster-item variant="line"/>
                </div>
                <loader-cluster-item variant="button"/>
            </div>
            <loader-cluster-item variant="button"/>
        </template>
        
        <!--Mentor request card-->
        <template v-if="variant === 'mentor-request-card'">
            <div class="card">
                <div class="mentor-request-card__inner">
                    <loader-cluster-item variant="long-text" class="mb-3"/>
                    <loader-cluster-item variant="text" class="mb-7"/>
                    <loader-cluster-item variant="short-text" class="mb-2"/>
                </div>
                <div class="px-5 border-t border-b">
                    <loader-cluster-item variant="long-text" class="my-3"/>
                </div>
                <div class="px-5 border-b">
                    <loader-cluster-item variant="long-text" class="my-3"/>
                </div>
                <loader-cluster-item variant="button" class="m-5"/>
            </div>
        </template>

        <!-- Mentor agreement form -->
        <template v-if="variant === 'mentor-agreement-form'">
            <div class="card py-5 px-6">
                <div class="mt-5 mb-8">
                    <loader-cluster-item variant="short-text" class="mb-5"/>
                    <loader-cluster-item variant="block"/>
                </div>
                <div class="mb-8 grid cols-3">
                    <div>
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="button"/>
                    </div>
                    <div>
                        <loader-cluster-item variant="short-text" :make-invisible="true" class="mb-5"/>
                        <loader-cluster-item variant="button"/>
                    </div>
                </div>
                <div class="mb-8 grid cols-3">
                    <div>
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="button"/>
                    </div>
                    <div>
                        <loader-cluster-item variant="short-text" :make-invisible="true" class="mb-5"/>
                        <loader-cluster-item variant="button"/>
                    </div>
                </div>
                <div class="mb-8">
                    <loader-cluster-item variant="short-text" class="mb-5"/>
                    <loader-cluster-item variant="button"/>
                </div>
                <div class="mb-8">
                    <loader-cluster-item variant="short-text" class="mb-5"/>
                    <loader-cluster-item variant="button"/>
                </div>
                <div>
                    <loader-cluster-item variant="short-text" class="mb-5"/>
                    <loader-cluster-item variant="button"/>
                </div>
            </div>
        </template>

        <!-- Mentor information card -->
        <template v-if="variant.indexOf('mentor-information-card') > -1">
            <div class="card py-5 px-6">
                <div class="mt-5 mb-6">
                    <loader-cluster-item variant="short-text" class="mb-5"/>
                </div>
                <div class="mb-6 grid cols-3 border-b pb-5">
                    <loader-cluster-item variant="button"/>
                    <loader-cluster-item variant="button"/>
                </div>
                <template v-if="variant === 'mentor-information-card--content'">
                    <div class="mt-5 mb-8">
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="block"/>
                    </div>
                    <div class="mt-5 mb-8">
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="block"/>
                    </div>
                    <div>
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <div class="flex gap-1 items-center">
                            <loader-cluster-item variant="avatar--lg"/>
                            <div>
                                <loader-cluster-item variant="long-text" class="mb-3"/>
                                <loader-cluster-item variant="button"/>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="variant === 'mentor-information-card--agreement'">
                    <div class="mt-5 mb-6">
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="short-text"/>
                    </div>
                    <div class="mt-5 mb-7">
                        <loader-cluster-item variant="short-text" class="mb-5"/>
                        <loader-cluster-item variant="short-text"/>
                    </div>
                    
                    <loader-cluster-item variant="short-text" class="mb-4"/>
                    <ul class="loader-cluster__bullets mb-8">
                        <li><loader-cluster-item variant="long-text"/></li>
                        <li><loader-cluster-item variant="long-text"/></li>
                        <li><loader-cluster-item variant="long-text"/></li>
                        <li>
                            <loader-cluster-item variant="text"/>
                            <loader-cluster-item variant="short-text" class="ml-5 mt-3"/>
                        </li>
                    </ul>

                    <loader-cluster-item variant="button"/>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import loaderClusterItem from "src/views/loaders/loader-cluster-item";

export default {
    name: "loader-cluster",
    components: {loaderClusterItem},
    props: {
        variant: String
    }
}
</script>
