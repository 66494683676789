<template>
    <div>
        <a :href="mentor.permalink" class="mentor-card card card--hover">
            <div class="card__body mentor-card__body">
                <template v-if="mentor.avatar_thumb_url">
                    <img :src="mentor.avatar_thumb_url" :alt="'Mentor avatar: ' + mentor.title" class="mentor-card__avatar avatar avatar--xl" />
                </template>
                <h3 class="mentor-card__title">{{ mentor.title }}</h3>
                <p class="mentor-card__bio">
                    {{ mentor.excerpt }}
                </p>
            </div>
            <div class="mentor-card__footer">
                <p class="mentor-card__footer-cta">{{ readMore }}</p>
                <icon name="chevron-right" class="mentor-card__footer-icon" />
            </div>
        </a>
    </div>
</template>

<script>
import i18n from 'src/i18n';
import Icon from "src/views/common/icon";

export default {
    name: "MentorCard",
    props: {
        mentor: {
            type: Object,
            required: true
        }
    },
    components: {
        Icon
    },
    computed: {
        readMore() {
            return i18n.global.t( 'common.read_more' );
        },
        currentLocale() {
            let url = new URL( window.location );
            let params = new URLSearchParams(url.search);
            let lang = params.get('lang');

            return lang ? lang : '';
        },
        getMentorDescription() {
            if( this.currentLocale == 'es' ) {
                return this.mentor._content_es_ES;
            }
            if( this.currentLocale == 'fr' ) {
                return this.mentor._content_fr_FR;
            }

            return this.mentor.content;
        }
    },
}
</script>
