<!--  -->
<!--
    # This has now been forked into vc4a-tabs in styles/src/components/vue/vc4a-tabs.vue
        Please use that version instead

    ## Styles: 
        Find styles in resources/scss/global-components/_nav-tabs.scss

    ## Example code:
        <nav-tabs
            :tabs-data-json="tabsDataArr"
        >
            // slots (see below)
        </nav-tabs>

    ## Slot syntax:
        Feed in content in slots like this:

        NB: slot names must be sequential, starting at 0. 
        Don't miss any or the content won't show.
        
        <div :slot="0">Content for first tab</div>
        <div :slot="1">Content for second tab</div>
        <div :slot="2">Content for third tab</div>

    ## Data-formatting:
        the data prop needs to be done in a specific way when adding this component in PHP:
            i.e:
            tabs-data-json="<?php echo esc_attr( wp_json_encode( $tabsDataArr ) ) ?>"

    ## Example prop format:
        {
            'title': 'First tab title',
            'id': 'my-first-tab'
        },
        {
            'title': 'Second tab title',
            'id': 'my-second-tab'
        },
 -->

<template>
    <div class="vc4a-nav-tabs">
        <div :class="wrapTabsInContainer ? 'container': ''">
            <nav 
                class="nav"
            >
                <a 
                    v-for="(tabObj, tabIndex) in tabsDataArr"
                    :href="`#${tabObj.id || ''}`" 
                    class="nav__link"
                    :class="activeTab === tabIndex ? 'nav__link--active' : ''"
                    :key="'tab-' + tabIndex"
                    @click="activeTab = tabIndex"
                >
                    {{ tabObj.title }}
                </a>
            </nav>
        </div>
        <div class="vc4a-nav-tabs__content">
            <div 
                v-for="(tabObj, tabIndex) in tabsDataArr"
                :key="'tab-content-' + tabIndex"
                class="vc4a-nav-tabs__tab-content"
                :id="`#${tabObj.id || ''}`"
                :aria-expanded="activeTab === tabIndex"
            >
                <template v-if="!renderAllOnLoad">
                    <slot 
                        v-if="activeTab === tabIndex"
                        :name="tabIndex"
                    />
                </template>
                <!-- Workaround for vanillajs init onload functions -->
                <template v-else>
                    <div v-show="activeTab === tabIndex">
                        <slot :name="tabIndex" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "NavTabs",
    props: {
        defaultActiveTab: {
            type: [ String, Number ],
            default: -1,
        },
        tabsDataJson: {
            type: [ String, Array ],
            required: true,
        },
        wrapTabsInContainer: {
            type: Boolean,
            default: false,
        },
        renderAllOnLoad: { // use-show on content. Helps vanillajs init functions
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            tabsDataArr: JSON.parse(this.tabsDataJson),
            activeTab: this.defaultActiveTab,
        }
    },
    methods: {
        handleUrlId() { // switch to active tab in url
            if (! window.location.hash.length) {
                return;
            }

            this.tabsDataArr.forEach((tabObj, index) => {
                if (`#${tabObj.id}` === window.location.hash) {
                    this.activeTab = index;
                }
            });
        }
    },
    computed: {
    },
    mounted() {
        this.handleUrlId();
    },
}
</script>
