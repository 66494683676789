export const VC4A_VARS = {
    VC4A_APP_URL: process.env.VUE_APP_VC4A_SITE || null,
    VC4A_SITE_URL: process.env.VUE_APP_VC4A_SITE_URL || null,
    DASHBOARD_SITE_URL: 'my.' + ( process.env.VUE_APP_VC4A_SITE_URL || null ),
    ASSETS_PATH: process.env.VUE_APP_VC4A_SITE + '/wp-content/themes/dashboard/public/' || null,
    STYLES_PATH: process.env.VUE_APP_VC4A_SITE + '/wp-content/themes/styles/' || null,
    RELATIVE_STYLES_PATH: '/wp-content/themes/styles/',
    DEFAULT_AVATAR_PATH: process.env.VUE_APP_VC4A_SITE + 'wp-content/themes/vc4africa/resources/images/avatars/avatar1.png' || null,
};

// Add 3 zeros (for milliseconds) to timestamp
export const addMillisecondToTimestamp = (timestamp) => {
    return timestamp * 1000;
}
