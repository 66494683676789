<!--Loader cluster item
    This creates all the items (lines, circles, etc) that make up the loader-cluster component
    
    HOW TO USE:
    <loading-cluster-item variant="avatar"/>
    
    PROPS:
        variant: (String) Specifies what snippet is to be loaded
            Currently available variants:
                avatar
                button
                line
                half-line
                short-line
                block
-->


<template>
    <div aria-hidden="true">
        <template v-if="variant === 'avatar'">
            <div class="loader-cluster__shimmer avatar" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'avatar--sm'">
            <div class="loader-cluster__shimmer avatar avatar--sm" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'avatar--lg'">
            <div class="loader-cluster__shimmer avatar avatar--lg" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'button'">
            <div class="loader-cluster__shimmer loader-cluster__button" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'line'">
            <div class="loader-cluster__shimmer loader-cluster__line" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'half-line'">
            <div class="loader-cluster__shimmer loader-cluster__half-line" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'short-line'">
            <div class="loader-cluster__shimmer loader-cluster__short-line" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'block'">
            <div class="loader-cluster__shimmer loader-cluster__block" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'text'">
            <div class="loader-cluster__shimmer loader-cluster__text" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'short-text'">
            <div class="loader-cluster__shimmer loader-cluster__short-text" :class="getDynamicClasses"></div>
        </template>
        <template v-else-if="variant === 'long-text'">
            <div class="loader-cluster__shimmer loader-cluster__long-text" :class="getDynamicClasses"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: "loader-cluster-item",
    props: {
        variant: String,
        makeInvisible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getDynamicClasses() {
            return this.makeInvisible ? 'loader-cluster-item--invisible' : '';
        }
    }
}
</script>
