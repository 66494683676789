<!--Modal
    This component adds a modal to the DOM but hidden by default
    
    HOW TO USE:
    
    <modal-basic :show-on-load="true">
        <template #title>This is the modal title</template>
        <template #toggle-btn>Click me to open/close</template> // outside of modal. Only way to open it.
        <template #close-btn>
            <div class="btn btn--primary">Click me to open/close</div>
        </template> // Prototype only. Functional but doesn't combine with content nicely.
        <div>Main content goes here</div> // default slot for content
    </modal-basic>
    
    PROPS:
        show:   toggles modal visibility
        id:     gives the modal an ID
        visibleOverflow: sets `overflow: visible;` style
        loadAsHiddenRender: render but hide onload (good for if items need to be in DOM on load)
    
    // Close button ("X" in header):
    Clicking the close icon emits a "close" event

    // Close button (with text):
    You can close the modal externally by setting a ref on the component and then triggering the following:
    `this.$refs['my-custom-ref-string'].closeModal();`

    <div class="btn btn--secondary" @click="handleCancelBtn()">Cancel</div>
-->

<!-- TODO: update docs (above) -->
<!-- TODO: error (when signed out): Templates should only be responsible for mapping the state to the UI -->

<template>
    <div 
        class="modal__wrapper"
    >
        <!-- NB the double-layering is weird, but allows for the option to render the (hidden) component on load -->
        <template v-if="showTheModal || loadAsHiddenRender">
            <div v-show="showTheModal">
                <div 
                    class="modal is-active" 
                    :class="this.modifierClass"
                >
                    <div class="modal__wrap" :id="id">
                        <div @click.prevent="closeModal" class="modal__bg-close"></div>
                        <div 
                            class="modal__content" 
                            :style="customStylesStr"
                        >
                            <div
                                v-if="$slots.title"
                                class="modal__header"
                            >
                                <h6 class="modal__title"><slot name="title"></slot></h6>
                            </div>
                            <button
                                v-if="showCloseBtn"
                                class="modal__close"
                                @click.prevent="closeModal"
                            >
                                <vc4a-icon name="close"></vc4a-icon>
                            </button>
                            <div class="modal__body">
                                <!-- Content / default slot -->
                                <slot/>
                            </div>
                            <div @click.prevent="toggleModal" class="hide-if-empty">
                                <slot name="close-btn"></slot>
                            </div>
                        </div>
                        <div 
                            class="modal__overlay" 
                            @click.prevent="handleClickModalOverlay"
                        ></div>
                    </div>
                </div>
            </div>
        </template>
        <div @click.prevent="toggleModal" class="hide-if-empty inline-flex">
            <slot name="toggle-btn"></slot>
        </div>
    </div>
</template>

<script>
    import Vc4aIcon from '$globalsrc/common/vc4a-icon';

    export default {
        name: 'ModalBasic',
        components: {
            Vc4aIcon,
        },
        props: {
            id: String,
            showOnLoad: {
                type: Boolean,
                default: false
            },
            modifierClass: String,
            closeOnOverlayClick: {
                type: Boolean,
                default: true
            },
            maxWidth: {
                type: String,
                default: '28rem'
            },
            showCloseBtn: {
                type: Boolean,
                default: true
            },
            visibleOverflow: {
                type: Boolean,
                default: false
            },
            loadAsHiddenRender: { // useful if elements need to be in dom on load
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showTheModal: this.showOnLoad,
            }
        },
        methods: {
            openModal(){
                this.showTheModal = true;
            },
            closeModal(){
                this.showTheModal = false;
            },
            toggleModal(){
                if(!this.showTheModal) {
                    this.openModal();
                    return;
                }

                this.closeModal();
            },
            handleClickModalOverlay() {
                if(!this.closeOnOverlayClick) {
                    return;
                }
                this.closeModal();
            },
        },
        computed: {
            customStylesStr() {
                let str = `max-width: ${this.maxWidth};`;

                if (this.visibleOverflow) {
                    str += ' overflow: visible;'    
                }

                return str;
            },
        },
        mounted() {
            // esc key to close modal
            window.addEventListener('keydown', (e) => {
                if (e.key == 'Escape') {
                    this.closeModal();
                }
            });
        },
    };
</script>
