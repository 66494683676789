<template>
    <div>
        <nav role="navigation" class="pagination" aria-label="Pagination navigation">
            <template v-if="currentPage > 1">
                <a :href="getPagenumLink( currentPage - 1 )" class="btn btn--icon">
                    <icon name="chevron-left" />
                </a>
            </template>
            <template v-else>
                <button class="btn btn--icon" disabled>
                    <icon name="chevron-left" />
                </button>
            </template>

            <ul class="pagination__items list-unstyled">
                <li v-for="page in getPageNumbers(currentPage, maxNumPages)">
                    <template v-if="page === currentPage">
                        <a :href="getPagenumLink( page )"
                           class="pagination__item is-active"
                           aria-current="true">
                            {{ page }}
                        </a>
                    </template>
                    <template v-else>
                        <a :href="getPagenumLink( page )"
                           class="pagination__item"
                        >
                            {{ page }}
                        </a>
                    </template>
                </li>
            </ul>

            <template v-if="currentPage < maxNumPages">
                <a :href="getPagenumLink( currentPage + 1 )" class="btn btn--icon">
                    <icon name="chevron-right" />
                </a>
            </template>
            <template v-else>
                <button class="btn btn--icon" disabled>
                    <icon name="chevron-right" />
                </button>
            </template>
        </nav>
    </div>
</template>

<script>

import Icon from "src/views/common/icon";

export default {
    name: "Pagination",
    props: {
        currentPage: {
            type: [Number, String],
            default: 1,
        },
        maxNumPages: {
            type: [Number, String],
            default: 1,
        },
        foundPosts: {
            type: [Number, String],
            default: 0,
        }
    },
    components: {
        Icon
    },
    methods: {
        getPagenumLink: function( pagenum ) {
            if( ! Number.isInteger(pagenum) ) {
                return 'return:false';
            }

            let url = new URL(window.location);
            let params = new URLSearchParams(url.search);
            url.pathname += 'page/' + pagenum + '/';

            return `${url.pathname}?${params}`
        },
        getPageNumbers: function( currentPage, maxNumPages ) {
            const maxPagingLength = 3;
            let pageNumbers = [];

            // Just list all?
            if( maxNumPages < maxPagingLength ) {
                for (let i = 1; i <= maxNumPages; i++) {
                    pageNumbers.push(i);
                }

                return pageNumbers;
            }

            // List until max paging length?
            if( currentPage === 1 ) {
                for (let i = 1; i <= maxPagingLength; i++) {
                    pageNumbers.push(i);
                }

                if( maxNumPages > maxPagingLength ) {
                    pageNumbers.push('· · ·');
                    pageNumbers.push(maxNumPages);
                }

                return pageNumbers;
            }

            // Compress pagination.
            let start = Math.max( 2, currentPage-2 );
            let end = Math.min( Math.max( 1, currentPage+2 ), maxNumPages-1 );
            pageNumbers.push(1);
            for (let i = start; i < currentPage; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push(currentPage);
            for (let i = end; i < maxNumPages; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push(maxNumPages);

            return pageNumbers;
        }
    }
}
</script>
