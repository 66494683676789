<!--Alert
    This component creates alerts
    
    HOW TO USE:
    
    <alert title="Title name goes here"
           message="Message goes here"
           :dismissible= true
           cta-text="Call to action"
           cta-url="#"
           :high-contrast="true"
           type="success"
           icon="arrow-upward"
    />
    
    PROPS:
        title:          Alert title
        message:        Text content of the alert
        type:           Alert variant
        ctaText:        Call to action text
        ctaUrl:         Call to action Url
        Dismissible:    (Boolean) whether or not alert is dismissible
        highContrast:   (Boolean) whether or not alert is high-contrast variant
        icon:           (String) icon name. Overrides default alert icon (Optional)
        
    Clicking the close icon emits a "delete" event and removes the alert from the DOM
-->

<template>
    <div>
        <div class="alert" :class="['alert--' + type, { 'alert--high-contrast': highContrast }, className]">
            <icon class="alert__icon" :name="(icon) ? icon : defaultIcon[type]"/>
            <div class="alert__body">
                <h6 class="alert__title" v-if="title">{{ title }}</h6>
                <slot>
                    <p class="alert__desc" v-if="message">
                        {{ message }}
                    </p>
                </slot>
                <a :href="ctaUrl" class="alert__cta" v-if="(ctaText && ctaUrl)">
                    {{ ctaText }}
                    <icon name="chevron-right"></icon>
                </a>
            </div>
            <button class="alert__close" v-if="dismissible" @click="deleteAlert">
                <icon name="close" />
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Alert',
        props: {
            title: String,
            message: String,
            className: String,
            type: String,
            ctaText: String,
            ctaUrl: String,
            dismissible: {
                type: Boolean,
                default: false
            },
            highContrast: {
                type: Boolean,
                default: false
            },
            icon: String
        },
        
        data(){
          return {
              defaultIcon: {
                  info: 'alert-circle',
                  success: 'checkmark-circle',
                  warning: 'alert-triangle',
                  danger: 'slash'
              }
            }
        },
        
        methods: {
            deleteAlert: function (){
                this.$emit('delete');
                this.$destroy(); // Destroys the vue listeners
                this.$el.parentNode.removeChild(this.$el) // removes the alert from the DOM
            }
        }
    };
</script>
