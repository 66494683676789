<template>
    <div class="flex flex-wrap gap-1">
        <vc4a-select
            :placeholder="$t('mentor_filter.panel.sort_by')"
            :options="sortOptions"
            @onInput="handleInputChange($event)"
            track-by="value"
            label="label"
            :summarised="true"
            class="find-mentor-hero__sort"
            :multiple="false"
        />
        <button class="btn btn--secondary" @click="toggleFilterPanel()"> {{ $t('mentor_filter.panel.toggle_btn') }} <icon name="chevron-down" /></button>
    </div>
</template>

<script>
// TODO: The scripts here are a duplicate with what we have on the mentor-filter.vue file. It needs refactoring.
import Vc4aSelect from "src/views/utility/vc4a-select";
import i18n from "src/i18n";
import Icon from "src/views/common/icon";

export default {
    name: "MentorHeroActions",
    components: {
        Vc4aSelect,
        Icon
    },
    props: {
        postsPerPage: {
            type: [Number, String],
            default: 18
        },
        currentPage: {
            type: [Number, String],
            default: 1
        },
        partnerId: {
            type: [Number, String],
            default: 0,
            required: false,
        },
    },
    data(){
        return {
            filterPanelVisible: false,
            queryInProgress: false,
            errorMessage: '',
            toggleIsActive: false,
            mentor_type: [],
            sectors: [],
            base_location: [],
            focus_locations: [],
            topic: [],
            venture_stage: [],
            spoken_languages: [],
            filters: {
                mentor_type: [],
                sectors: [],
                topic: [],
                venture_stage: [],
                spoken_languages: [],
                focus_locations: [],
            },
            hierarchical: [
                'focus_locations'
            ],
            where: {
                'sectors': {
                    'parentId': 0
                }
            },
            sortByTermId: [
                'mentor_support',
                'venture_stage',
                'mentor_type',
            ],
            flash: {
                message: '',
                type: ''
            },
            loadingTerms: 0,
            url: {},
            params: {},
            paged: 1,
            sortBy: {
                label: 'Date DESC',
                value: 'date_desc',
            },
            mentors: [],
        }
    },
    mounted() {
        this.sortBy = this.sortOptions[0];
    },
    methods: {
        toggleFilterPanel () {
            document.querySelector('.find-mentor-hero__filters').classList.toggle('is-active');
        },
        handleInputChange(event) {
            if( event === undefined ) {
                return;
            }

            // TODO: swap out direct dom access for refs-based (or other appropriate) functionality
            document.querySelector('.mentor-filter__wrap').setAttribute('data-sort', event.value);

            let evt = new CustomEvent("mentor-filter-sort", {detail: event});
            // TODO: turn into an emit (this functionality is inappropriate for vuejs)
            window.dispatchEvent(evt);

            this.sortBy = {...event}; // Does this do ANYTHING?! // TODO: remove all mentions from file?

            // Set url params
            const url = new URL(window.location);
            let urlParams = new URLSearchParams(url.search);
            urlParams.set('orderby', event.orderby);
            urlParams.set('order', event.order);

            window.history.replaceState({}, '', `${url.pathname}?${urlParams}`);
        },
    },
    computed: {
        sortOptions() {
            return [
                {
                    label: i18n.global.t( 'sort.date_desc' ),
                    value: 'date_desc',
                    orderby: 'date',
                    order: 'DESC',
                },
                {
                    label: i18n.global.t( 'sort.date_asc'),
                    value: 'date_asc',
                    orderby: 'date',
                    order: 'ASC',
                },
                {
                    label: i18n.global.t( 'sort.name_asc' ),
                    value: 'name_asc',
                    orderby: 'title',
                    order: 'ASC',
                },
                {
                    label: i18n.global.t( 'sort.name_desc' ),
                    value: 'name_desc',
                    orderby: 'title',
                    order: 'DESC',
                },
            ];
        },
    }
}
</script>
