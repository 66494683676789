import {
    GET_HIERARCHICAL_TERMS,
    GET_TERMS
} from "src/graphql/terms";
import { apolloClient } from 'src/graphql/apollo-client';
import i18n from "src/i18n";

export const getTermsForTaxonomy = async ( taxonomySlug, siteId, perPage, postId, hierarchical, where ) => {
    if( ! perPage ) {
        perPage = 1000;
    }
    let variables = {
        where: {
            taxonomySlug: taxonomySlug,
            siteId: 1,
        },
        first: perPage
    };
    if( postId ) {
        variables.where.postId = postId;
    }
    let query = GET_TERMS;
    if( hierarchical ) {
        query = GET_HIERARCHICAL_TERMS;
        variables.where.parentId = 0;
    }
    if( where ) {
        variables.where = { ...variables.where, ...where };
    }
    return new Promise((resolve, reject) => {
        apolloClient.query({
            query: query,
            variables: variables,
        }).then((data) => {
            resolve(data.data.terms.edges)
        }).catch((error) => {
            reject(error)
        })
    });
}

export const filterTerms = ( terms, hierarchical, objectKeyColumn, grouped ) => {
    let retvalue = [];
    if( ! terms.length ) {
        return retvalue;
    }

    if( hierarchical ) {
        if( grouped ) {
            for( let i=0; i<terms.length; i++ ) {
                let level2 = terms[i].node.childItems.nodes;
                if( level2.length ) {
                    for( let j=0; j<level2.length; j++ ) {
                        let node = {...level2[j]}
                        delete node.__typename
                        node.childItems = []
                        let level3 = level2[j].childItems.nodes;
                        if( level3.length > 0 ) {
                            // descending to keep countries sorted asc.
                            for( let k=level3.length-1; k>=0; k-- ) {
                                let node2 = {...level3[k]};
                                delete node2.__typename;
                                node.childItems.push({...node2})
                            }
                        }
                        retvalue[node.slug] = node;
                    }
                }
            }
        } else {
            for( let i=0; i<terms.length; i++ ) {
                let level2 = terms[i].node.childItems.nodes;
                if( level2.length ) {
                    for( let j=0; j<level2.length; j++ ) {
                        let level3 = level2[j].childItems.nodes;
                        if( level3.length ) {
                            for( let k=0; k<level3.length; k++ ) {
                                let node = {...level3[k]};
                                delete node.__typename;
                                retvalue[node.name] = node;
                            }
                        } else {
                            let node = {...level2[j]};
                            delete node.__typename;
                            retvalue[node.name] = node;
                        }
                    }
                } else {
                    let node = {...terms[i].node};
                    delete node.__typename;
                    retvalue[node.name] = node;
                }
            }
        }
    } else {
        if( objectKeyColumn ) {
            terms.map(term => {
                let node = {...term.node};
                delete node.__typename;

                retvalue[ node[ objectKeyColumn ] ] = node;

                return node;
            });
        } else {
            return terms.map(term => {
                let node = {...term.node};
                delete node.__typename;

                return node;
            });
        }
    }

    return retvalue;
}

export const getTermName = ( term ) => {
    switch ( i18n.global.locale ) {
        case 'es':
            return term.name__es_ES ? term.name__es_ES : term.name;
        case 'fr':
            return term.name__fr_FR ? term.name__fr_FR : term.name;
        case 'en':
        default:
            return term.name;
    }
}

export const getTermLabel = () => {
    switch ( i18n.global.locale ) {
        case 'es':
            return 'name__es_ES';
        case 'fr':
            return 'name__fr_FR';
        case 'en':
        default:
            return 'name';
    }
}
