<!-- 
    The "email" section for the cohort settings menu
 -->

<template>
    <div class="card py-6 px-5">
        <div class="form-group">
            <label class="mb-0" for="reminder_emails_yes">
                <!-- "Enable reminder emails" -->
                {{ $t('cohorts.settings.messaging.reminder.title') }}
            </label>
            <div class="cohorts-info-row__note paragraph-sm mb-3">
                <!-- "Enables reminder emails going out[...]" -->
                {{ $t('cohorts.settings.messaging.reminder.body') }}
            </div>
            <div class="flex flex-col">
                <!-- "Yes" -->
                <vc4a-radio
                    name="send_reminder_emails"
                    class="paragraph mb-3"
                    value="1"
                    :bind-to="enableReminderEmails"
                    :label-str="$t('common.options.yes')"
                    @radioValueChanged="(event) => enableReminderEmails = event.target.value"
                />

                <!-- "No" -->
                <vc4a-radio
                    name="send_reminder_emails"
                    class="paragraph mb-0"
                    value="0"
                    :bind-to="enableReminderEmails"
                    :label-str="$t('common.options.no')"
                    @radioValueChanged="(event) => enableReminderEmails = event.target.value"
                />
            </div>
            <button
                class="btn btn--primary mt-5"
                @click="handleClickSubmit()"
            >
                <!-- "Save changes" -->
                {{ $t('common.ctas.save_changes') }}
            </button>
        </div>

        <div 
            v-if="enableReminderEmails === '1'"            
            id="#reporting-emails"
        >
            <h5 for="reporting-emails" class="mb-5 pt-9">
                <!-- Reporting emails -->
                {{ $t('cohorts.settings.messaging.reporting.title') }}
            </h5>

            <ul class="cohort-settings__settings-list list-unstyled">
                <li
                    v-for="settingConfigObj in settingsConfigArr"
                    :key="settingConfigObj['slug']"
                    class="flex py-5 border-b"
                >
                    <div class="flex-grow">
                        <!-- Setting Title -->
                        <label class="mb-0">{{ settingConfigObj['title'] }}</label>
                        
                        <!-- Setting Description -->
                        <div class="cohorts-info-row__note paragraph-sm mb-0">
                            {{ settingConfigObj['desc'] }}
                        </div>
                    </div>
                    
                    <!-- Button and modal -->
                    <cohort-email-edit
                        :email-type="settingConfigObj['slug']"
                        :modal-title="settingConfigObj['title']"
                        :program-data-obj="programDataObj"
                    ></cohort-email-edit>
                </li>
            </ul>
        </div>
        
        <!-- Success notification -->
        <vc4a-flash
            v-if="showSuccessFlash"
            type="success"
            :message="$t('common.success.changes')"
        />
        <!-- Fail notification -->
        <vc4a-flash
            v-if="showFailureFlash"
            type="danger"
            :message="$t('common.errors.basic')"
        />
    </div>
</template>

<script>
import CohortEmailEdit from '$globalsrc/cohorts/cohort-email-edit';
import Vc4aRadio from '$globalsrc/common/form/vc4a-radio';
import Vc4aFlash from '$globalsrc/common/vc4a-flash';

export default {
    name: "CohortSettingsEmail", 
    components: {
        CohortEmailEdit,
        Vc4aRadio,
        Vc4aFlash,
    },  
    props: {
        nonce: {
            type: String,
            required: true
        },
        cohortId: {
            type: Number,
            required: true
        },
        isChecked: {
            type: Boolean,
            default: false,
        },
        programDataJsonStr: { // contains 'title' and 'id'
            type: String,
            required: true,
        },
        settingsConfigArrJsonStr: { // contains array of settings
            type: String,
            required: true,
        }
    },
    data() {
        return {
            programDataObj: JSON.parse(this.programDataJsonStr),
            settingsConfigArr: JSON.parse(this.settingsConfigArrJsonStr),
            enableReminderEmails: JSON.parse(this.isChecked) === 1 ? '1' : '0',
            showSuccessFlash: false,
            showFailureFlash: false,
        }
    },
    methods: {
        async handleClickSubmit() {
            this.queryInProgress = true;
            const paramsObj = {
                "_nonce": this.nonce,
                "send_reminder_emails": this.$data.enableReminderEmails,
                "cohort_id": this.cohortId
            }
            console.log(paramsObj);
            await fetch(
                ajaxurl + '?action=cohort_settings&' + new URLSearchParams(paramsObj).toString(),
                {
                    method: 'POST',
                }
            ).then((response) => {
                if (response.status > 200) {
                    response.json().then((json) => {
                        this.queryInProgress = false;
                    });
                    return;
                }

                this.queryInProgress = false;
                this.showFlash('success');
            }).catch((error) => {
                console.error(error);
                this.showFlash('failure');
                this.queryInProgress = false;
            })

            this.$refs['cohort-email-edit'].closeModal();
        },
        showFlash(type) {
            if (type === 'success') {
                this.showSuccessFlash = true;
            } else if (type === 'failure') {
                this.showFailureFlash = true;
            }

            setTimeout(() => {
                this.showSuccessFlash = false;
                this.showFailureFlash = false;
            }, '4000');
        },
    }
}
</script>
