import mentorsEn from 'src/i18n/lang/en.js';
import mentorsEs from 'src/i18n/lang/es.js';
import mentorsFr from 'src/i18n/lang/fr.js';

import mainThemeEn from '/src/i18n/lang/en.js';
import mainThemeEs from '/src/i18n/lang/es.js';
import mainThemeFr from '/src/i18n/lang/fr.js';

import datetimeFormats from "/src/i18n/datetimeFormats";
import numberFormats from "/src/i18n/numberFormats";

import { detectLang } from './languageDetection';
import { createI18n } from 'vue-i18n';

// Function that combines language objects from mentors with main theme
const combineLangObj = (lang1, lang2) => {

    //Create copy of first lang object
    let combinedLangJsObj = { ...lang1 };

    for (const [ key, value ] of Object.entries( lang2 )) {

        // If key exists in both objects, combine objects
        if ( key in lang1 ) {
            combinedLangJsObj[ key ] = Object.assign( combinedLangJsObj[ key ], lang2[ key ] );
        } else {

            // If key doesn't exist, add key to combined array
            combinedLangJsObj = Object.assign( combinedLangJsObj, lang2[ key ] );
        }
    }

    return combinedLangJsObj;
};

const i18n = createI18n({
    fallbackLocale: 'en',
    locale: detectLang(),
    globalInjection: true,
    legacy: false,
    messages: {
        en: combineLangObj( mentorsEn, mainThemeEn ),
        es: combineLangObj( mentorsEs, mainThemeEs ),
        fr: combineLangObj( mentorsFr, mainThemeFr )
    },
    datetimeFormats: datetimeFormats,
    numberFormats: numberFormats
});

export default i18n;
