<!-- 
    <btn-remove-from-cohort
        nonce="<?php echo wp_create_nonce( 'team_remove' ) ?>"
        team-member-id="<?php echo esc_attr( $member->getId() ) ?>"
        team-id="<?php echo esc_attr( $member->getTeamId() ) ?>"
        team-type="<?php echo esc_attr( $member->getTeamType() ) ?>"
        class="hidden-link" // if you want a black color theme when not hovered (default is green)
    >
        {{ $t(
            'placeholder_key.btn_remove', 
            'Remove from cohort'
        ) }}

        // OR:

        <vc4a-icon 
            v-else-if="btnAppearance === 'icon'"
            name="trash"
        />
    </btn-remove-from-cohort>
-->

<template>
    <a 
        href="#"
        @click.prevent="submitAjax()"
        class="btn-remove-from-cohort"
    >
        <slot/>
    </a>
</template>

<script>


import Vc4aIcon from '$globalsrc/common/vc4a-icon';
export default {
    name: "BtnRemoveFromCohort",
    components: {
        Vc4aIcon
    },
    props: {
        nonce: {
            type: String,
            required: true
        },
        teamMemberId: {
            type: String,
            required: true
        },
        teamId: {
            type: String,
            required: true
        },
        teamType: {
            type: String,
            required: true
        },
        btnAppearance: {
            type: String,
            default: 'text' // can also be 'icon'
        }
    },
    data() {
        return {
            paramsObj: {
                "_nonce": this.nonce,
                "team_member_id": this.teamMemberId,
                "team_id": this.teamId,
                "team_type": this.teamType,
            }
        }
    },
    methods: {
        async submitAjax() {
            this.queryInProgress = true;

            let url = ajaxurl + '?action=vc4a_team_delete_member&';
            const paramsJson = JSON.stringify(this.paramsObj);
            url += (new URLSearchParams(this.paramsObj).toString());

            await fetch(url, {
                method: 'POST',
            }).then((response) => {
                if (response.status > 200) {
                    response.json().then((json) => {
                        this.queryInProgress = false;
                    });
                    return;
                }

                this.queryInProgress = false;
                window.location.reload();

            }).catch((error) => {
                console.error(error);
                this.queryInProgress = false;
            })
        },
    },
}
</script>
