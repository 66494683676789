import LanguageDetector from 'i18next-browser-languagedetector';

const langDetectorOptions = {
    // order and from where user language should be detected.
    order: [ 'querystring', 'htmlTag', 'navigator' ],
    querystring: { lookupQuerystring: 'lang' },
    htmlTag: { htmlTag: document.documentElement },
    navigator: {},
    supportedLanguages: ['en', 'es', 'fr'],
    fallbackLang: 'en',
};

const LanguageDetection = new LanguageDetector();
LanguageDetection.init(langDetectorOptions)

/**
 *  isLangSupported
 *  This function checks in the detected languages from each detection criteria is supported by VC4A.
 *      returnedLangVal => ( Array || String )
 *          Navigator (browser) checks returns array
 *          querystring, htmlTag checks returns a string
 */
let isLangSupported = (returnedLangVal) => {
    if (Array.isArray(returnedLangVal)) {
        let validLanguagesArr = returnedLangVal.filter( lang => langDetectorOptions.supportedLanguages.includes(lang) )
        if (validLanguagesArr.length) {
            return validLanguagesArr[0];
        }
        return undefined;
    } else {

        // return falsy if returnedLangVal is falsy or is not supported
        return (langDetectorOptions.supportedLanguages.includes(returnedLangVal)) ? returnedLangVal : undefined;
    }
}

export function detectLang () {
    let detectedLang;

    // Iterate through each criterion in order until a language is detected
    langDetectorOptions.order.every((criterion) => {

        // Use language detector package to detect language for each criterion
        detectedLang = LanguageDetection.detectors[ criterion ].lookup(langDetectorOptions[criterion]);

        // return falsy if detectedLang is falsy or is not supported
        detectedLang = (detectedLang) ? isLangSupported(detectedLang) : undefined;
        return !detectedLang;
    });

    return detectedLang || langDetectorOptions.fallbackLang
};
