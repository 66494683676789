<!--Tags
    This component creates tags.
    
    HOW TO USE:
    <tag type="info" size="lg" :icon="pin">Nigeria</tag>
     PROPS:
        type: (String) Defines tag type (success, info, warning, danger) (optional, default color variant will be used if not specified)
        size: (String) Tag size variants (sm, lg) (optional)
        icon: (String) Name of icon sprite (optional)
-->

<template>
    <span class="tag" :class="[ (type)? 'tag--'+ type: '', (size)? 'tag--'+ size: '' ]">
        <icon v-if="icon" :name="icon"></icon>
        <span class="tag__name"><slot/></span>
    </span>
</template>

<script>
export default {
    name: "tag",
    props: {
        icon: String,
        type: String,
        url: String,
        size: String
    }
}
</script>
