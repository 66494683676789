<template>
    <div class="eq-modal">
        <Vc4aNavBarClosable
            @close:nav-bar-closable="$emit('close:eq-modal')"
        />
        <div class="eq-modal__body">
            <div class="container container--sm">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {VC4A_VARS} from '@/shared/utlis';
import Vc4aNavBarClosable from "@stylesComponents/vue/vc4a-nav-bar-closable.vue"

export default {
    name: "eq-modal",
    emits: ['close:eq-modal'],
    components: {
        Vc4aNavBarClosable
    },
    data() {
        return {
            vc4aLogoPath: `${VC4A_VARS.VC4A_SITE_ASSETS_PATH}images/svg/vc4a.svg`,
        }
    }
}
</script>
