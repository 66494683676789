<!-- ICON
    This component makes it easy to add an icon.

    How to use:
    <Icon name="checkmark-circle" />


-->

<template>
    <svg class="icon"
        xmlns="http://www.w3.org/2000/svg"
        :class="className"><use :href="svgPath + '#' + name"></use>
    </svg>
</template>

<script>
import { VC4A_VARS } from "src/shared/utils";

export default {
    name: "Icon",
    data() {
        return {
            icon: null,
            icons: {
                'sprite': '',
            }
        }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        className: {
            type: String,
            required: false
        }
    },
    computed: {
        getIcon() {
            return this.icons[this.iconName];
        },
        svgPath() {
            return VC4A_VARS.RELATIVE_STYLES_PATH + 'icons/sprite.svg'
        }
    },
    mounted() {
        this.icon = this.getIcon;
    }
}
</script>
