<template>
    <div class="vc4a-select">
        <multiselect
            v-model="selectedOptions"
            :placeholder="placeholder"
            :options="getData"
            :multiple="multiple"
            :close-on-select="autoClose"
            :max="max"
            open-direction="bottom"
            :track-by="getTermLabel()"
            :label="getTermLabel()"
            selectLabel=""
            selectGroupLabel=""
            selectedLabel="✓"
            deselectLabel="✕"
            deselectGroupLabel="✕"
            :openDirection="openDirection"
            :group-values="groupValues"
            :group-label="groupLabel"
            :group-select="groupSelect"
            :class="{ 'has-error': hasError, 'multiselect--group': groupSelect }"
            @close="$emit('close')"
            :disabled="disabled"
        >
        <!--
            NB this needs to return empty (not falsy) in order to trigger default/fallback slot content
            fallback content is formatted html tags of all selected options
        -->
            <template
                v-slot:selection
                v-if="summarised && selectedOptions !== undefined && selectedOptions.length > summarisedStartValue"
            >
                <span>{{getSummaryText}}</span>
            </template>
        </multiselect>
        <template v-if="toggleAll">
            <div class="form-checkbox mt-3">
                <input :id="globalId" type="checkbox" class="form-checkbox__input" v-model="toggleSelectAll" />
                <label :for="globalId" class="form-checkbox__label">{{ toggleAllText }}</label>
            </div>
        </template>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
    getTermName,
    getTermLabel
} from "src/shared/terms";
import i18n from "src/i18n";


export default {
    name: 'Vc4aSelect',
    props: {
        options: [Array, Object],
        placeholder: String,
        max: Number,
        multiple: {
            type: Boolean,
            default: true
        },
        autoClose: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        selected: [Array, Object],
        summarised: {
            type: Boolean,
            default: false
        },
        summarisedStartValue: {
            type: Number,
            default: 3
        },
        hasError: {
            type: Boolean,
            default: false
        },
        groupValues: {
            type: String,
            default: ''
        },
        groupLabel: {
            type: String,
            default: 'name'
        },
        groupSelect: {
            type: Boolean,
            default: false
        },
        openDirection: {
            type: String,
            default: 'bottom'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        toggleAll: {
            type: Boolean,
            default: false
        },
        toggleAllText: {
            type: String,
            default: 'Select all options'
        },
        taxonomy: {
            type: String,
            default: ''
        }
    },
    components: {
        Multiselect
    },
    emits:['onInput', 'close'],
    data() {
        return {
            selectedOptions: null,
            toggleSelectAll: false,
            allOptionsCount: 0,
            optionSelectAll: {
                name: '-- Select all --',
                name__es_ES: '-- Seleccionar todo --',
                name__fr_FR: '-- Tout sélectionner --',
                slug: 'option_select_all',
                term_id: 0
            },
            termsWithSelectAll: [
                'sectors'
            ],
            globalId: '',
            defaultLabel: 'name'
        }
    },
    beforeMount() {
        this.globalId = 'global-' + ( Math.floor(Math.random() * 1000) + 1 );

        this.selectedOptions = this.selected
        this.setAllOptionsCount();

        if( this.toggleAll && this.selectedOptions.length === this.allOptionsCount ) {
            this.toggleSelectAll = true;
        }
    },
    watch: {
        selected: {
            handler( newValue ) {
                if( Array.isArray( newValue ) && newValue.includes(this.optionSelectAll) ) {
                    let options = this.getOptions();
                    
                    // Delete the option to select all when checking options length.
                    this.selectedOptions.splice( this.selectedOptions.indexOf( this.optionSelectAll ) );
                    if( this.selectedOptions.length === options.length ) {
                        this.toggleAllOptions();
                    } else {
                        this.toggleAllOptions( options );
                    }
                } else if( newValue !== this.selectedOptions ) {
                    this.selectedOptions = newValue;
                }
            },
            deep: true
        },
        selectedOptions: {
            handler( newValue ) {
                // If there's the `global` toggle, de-select it.
                if( this.toggleAll && this.selectedOptions.length !== this.allOptionsCount ) {
                    this.toggleSelectAll = false;
                }
                this.$emit( 'onInput', newValue )
            },
            deep: true
        },
        toggleSelectAll( newVal, oldVal ) {
            if( newVal !== oldVal ) {
                if( newVal === true ) {
                    let options = this.getOptions();
                    this.toggleAllOptions( options )
                } else {
                    this.toggleAllOptions()
                }
            }
        }
    },
    computed: {
        getData() {
            let retval = [];

            if( this.selectAll ) {
                let item = this.optionSelectAll;
                if( this.groupValues ) {
                    item['childItems'] = [this.optionSelectAll];
                }
                retval.push( item )
            }

            if( typeof this.options === 'object' ) {
                let keys = Object.keys(this.options);

                keys.sort();
                for( let i=0; i<keys.length; i++ ) {
                    retval.push(this.options[ keys[i] ]);
                }
            } else {
                retval = this.options ? [...this.options] : [];
            }

            this.setAllOptionsCount()

            return retval;
        },
        // needed as initial render sometimes has empty values and errors out
        getSummaryText() {
            if (!this.selectedOptions || !this.selectedOptions.length) { // catch empty values pre-render
                return;
            }
            
            return this.selectedOptions.length + ' ' + i18n.global.t('options_selected');
        }
    },
    methods: {
        getTermName( term ) {
            return getTermName( term );
        },
        getTermLabel() {
            // if using localised labels e.g. `name__es_ES`
            if( this.label.indexOf('name__') > -1) {
                let itemToCheck = this.getData[0];
                
                if (this.groupSelect) { // if is hierarchical/nested select
                    itemToCheck = this.getData[0].childItems[0];
                }

                const labelIsValidKey = Object.keys(itemToCheck).includes(this.label);

                // if using localised label slug as a key will not return a value, use default label slug
                if (! labelIsValidKey) {
                    return this.defaultLabel;
                }
            }

            if( this.label !== 'getTermLabel' ) {
                return this.label ? this.label : this.defaultLabel;
            }

            return getTermLabel();
        },
        getOptions() {
            let retval = [];
            if( typeof this.options === 'object' ) {
                let keys = Object.keys(this.options);

                keys.sort();
                for( let i=0; i<keys.length; i++ ) {
                    retval.push(this.options[ keys[i] ]);
                }

                return retval;
            } else {
                retval = this.options;
            }

            return retval;
        },
        toggleAllOptions(options) {
            if( options ) {
                let key = 0;
                options.map( term => {
                    if( this.groupSelect ) {
                        term.childItems.map( childTerm => {
                            this.selectedOptions[key] = childTerm
                            key++;
                        } )
                    } else {
                        this.selectedOptions[key] = term
                        key++;
                    }
                } )
            } else if( this.selectedOptions.length === this.allOptionsCount ) {
                // Clear all selected values only when all options are selected.
                this.selectedOptions.splice(0);
            }
        },
        setAllOptionsCount() {
            let options = this.getOptions();
            if( ! options || options.length < 1) {
                return;
            }
            this.allOptionsCount = 0;
            options.map( term => {
                if( this.groupSelect ) {
                    term.childItems.map( childTerm => {
                        this.allOptionsCount++;
                    } )
                } else {
                    this.allOptionsCount++;
                }
            } )
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css">

</style>
