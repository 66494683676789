export default {
  "common": {
    "optional": "optionnel",
    "back_btn": {
      "to_profile": "Retour à la page de profil"
    },
    "read_more": "Lire la suite",
    "option_toggle_global": "Mondial",
    "loading": "Chargement ..."
  },
  "one": "Un",
  "two": "Deux",
  "three": "Trois",
  "four": "Quatre",
  "five": "Cinq",
  "file_reader_error": "Désolé, l'API FileReader n'est pas prise en charge.",
  "form_errors": "Veuillez remplir tous les champs du formulaire pour continuer",
  "form_success": "Les détails du profil ont été stockés avec succès.",
  "close": "Fermer",
  "onboarding": {
    "step": "Etape",
    "of": "sur",
    "progress_title": "Devenir un mentor",
    "progress_description": "Remplissez le formulaire ci-dessous pour devenir un mentor vc4a.",
    "title_step_1": "Biographie",
    "title_step_2": "Votre expérience de mentor",
    "title_step_3": "En savoir plus sur votre offre de mentorat",
    "save_and_continue": "Suivant",
    "submit_for_approval": "Soumettre pour approbation",
    "email_hint": "Vous recevrez des mises à jour sur cette adresse électronique. Vous pouvez ",
    "email_hint_link": "la modifier ici",
    "about_hint": "Brève description de votre profil.",
    "about_modal": {
      "title": "À propos - Exemple",
      "content": "Bonjour, je m'appelle Jack et j'ai créé une entreprise de médias sociaux et une solution de paiement au cours des 15 dernières années. Je m'intéresse à l'Afrique et à l'Amérique latine car je pense que ce sont les espaces de démarrage les plus excitants du continent en ce moment. Je cherche à soutenir les fondateurs de startups en démarrage dans leur phase de création, car je pense que les mentors peuvent alors ajouter le plus de valeur. Mes intérêts sont la fintech, les plateformes de médias sociaux et tout ce qui concerne le Web3."
    },
    "upload_new_picture": "Télécharger une image",
    "upload_new_picture_in_progress": "Téléchargement...",
    "remove_picture": "Retirer une image",
    "crop_avatar": "Culture avatar",
    "save_changes": "Enregistrer les modifications",
    "mentor_type": {
      "label": "Quel type de mentor êtes-vous ?"
    },
    "spoken_languages": {
      "label": "Langue parlée",
      "hint": "Il s'agit des langues que vous parlez et écrivez couramment pour les missions de mentorat."
    },
    "sectors": {
      "label": "Sélectionner ls secteurs d’activité où vous avez acquis de l'expérience au cours de votre carrière",
      "hint": "Sélectionnez au moins 1 secteur et au maximum 5 secteurs."
    },
    "geo_focus": {
      "label": "Sélectionnez vos préférences géographiques",
      "hint": "Il s'agit de pays/régions dans lesquels vous souhaitez encadrer des entrepreneurs, soit parce que vous êtes originaire de cet endroit, soit parce que vous y avez travaillé ou voyagé."
    },
    "topics": {
      "label": "Sélectionner les domaines d’expertise sur lesquels vous accompagnerez les entrepreneurs",
      "hint": "Sélectionnez au minimum 1 et au maximum 5 rubriques."
    },
    "mentorship_stage": {
      "label": "À quelle étape voulez-vous offrir un mentorat?",
      "hint": "Nous croyons que seuls les mentors expérimentés devraient choisir la croissance ou la maturité."
    },
    "number_of_calls": {
      "title": "La communication",
      "label": "Nombre d'appels",
      "hint": "Il s'agit du nombre de fois où vous êtes disposé à parler avec l'entrepreneur pendant la durée du mentorat."
    },
    "number_of_calls_alert": {
      "title": "Messagerie",
      "message": "Une discussion ouverte, via email, appel téléphonique ou message dans les limites définies entre l'entrepreneur et les mentors est la norme pour toutes les relations de mentorat."
    },
    "mentor_motivation": {
      "label": "Motivation du mentorat",
      "hint": "Pourquoi faites-vous du mentorat ?",
      "explain": "Expliquer nous en quelques mots votre motivation à devenir mentor",
      "why": "Expliquer les raisons de votre engagement est obligatoire."
    },
    "explainer": {
      "stages": {
        "title": "Explications des étapes de maturité des startups",
        "idea": {
          "title": "Étape Idea / Concept",
          "explainer": "C'est l'étape de l'idéation, où le (s) fondateur (s) fait (font) la recherche autour du \"Problème / Solution\" et travaille sur le \"Minimum Viable Product\" (MVP) pour tester la réponse sur le marché. Le mentor se concentrera probablement sur la façon de transformer une idée à partir d'un concept sur papier en une réalité."
        },
        "startup": {
          "title": "Étape de démarrage",
          "explainer": "Après avoir lancé le Produit Viable minimum (MVP), il s'agit de la phase d'essai de l’adéquation entre le  produit et le marché  et de procéder à des améliorations du produit  sur la base de la réponse du marché. Le mentor mettra probablement l'accent sur la composition de l'équipe, les retours du marché, l'adaptation du business model et ainsi de suite."
        },
        "growth": {
          "title": "Étape de croissance",
          "explainer": "Une fois l’adéquation entre le produit et le marché trouvé, il est maintenant temps de mettre en œuvre et de mettre à l'échelle les opérations et l'équipe. Le mentor se concentrera probablement sur les difficultés liées à la croissance auxquelles chaque entreprise en expansion fait face."
        },
        "mature": {
          "title": "Étape de maturité",
          "explainer": "La maturité signifie que les taux de croissance ralentissent, mais avec l'internationalisation vient la mise en place d'équipes locales pour adapter le produit aux subtilités et aux nuances culturelles de chaque nouvelle région. Durant cette étape, les possibilités de sortie peuvent également faire partie des discussions avec le mentor."
        }
      },
      "payment": {
        "title": "Comment fonctionne le paiement"
      },
      "type": {
        "title": "Explication des types de mentors"
      }
    },
    "payment_group": {
      "title": "Mentorat gratuit ou payant",
      "desc": "VC4A promeut le mentorat gratuit auprès des fondateurs d'entreprises en phase d'idée / concept et en phase de démarrage. Si vous offrez des services aux fondateurs d'entreprises en croissance ou matures, n'hésitez pas à indiquer vos honoraires.",
      "options": {
        "free": {
          "title": "Je veux offrir du mentorat gratuitement",
          "desc": "Gratuit pour toutes les phase de l'entreprise."
        },
        "paid": {
          "title": "Je veux offrir un mentorat moyennant des honoraires",
          "desc": "Uniquement pour les entreprises en phase de croissance et de maturité. Le mentorat auprès d'entreprises en phase d'idée / concept et/ de démarrage est toujours gratuit."
        }
      },
      "monthly_rate": "Définir le taux mensuel ($)"
    },
    "gender": {
      "label": "Sexe",
      "option_male": "Homme",
      "option_female": "Femme",
      "option_other": "Autres",
    }
  },
  "profile": {
    "edit_profile": "Editer le profil",
    "pending_alert": {
      "title": "En attente d'approbation",
      "message": "Votre profil de mentors VC4A a été créé et soumis pour approbation."
    },
    "approved_alert": {
      "title": "Profil approuvé",
      "message": "Félicitations, votre profil de mentor a été approuvé."
    },
    "mentorship_connection": "Connexion de mentorat",
    "month": "Mois",
    "includes": "Comprend",
    "benefits": {
      "messages": "Discussion illimitée, courrier électronique ou texte à l'intérieur des limites.",
      "checklist": "Liste de contrôle de connexion structurée pour faciliter le suivi.",
      "support": "Prise en charge des administrateurs VC4A."
    },
    "view_requests": "Afficher les demandes"
  },
  "edit_profile": {
    "page_title": "Editer le profil de votre mentor",
    "page_subtitle": "Vous apporte des modifications au contenu du profil de votre mentor. Pour modifier votre profil vc4a principal,",
    "click_here": "Cliquez ici",
    "mod_comments": {
      "title": "Commentaires du modérateur",
      "content": "Si vous avez mis à jour les modifications ci-dessus, vous pouvez soumettre votre profil pour examen en cliquant sur le bouton ci-dessous.",
      "re_review_cta": "Soumettre à nouveau pour examen",
      "success_title": "Succès!",
      "success_message": "Les modifications de profil ont été soumises avec succès pour révision.",
      "hint": "Votre demande a été soumise pour examen. Vous pouvez toujours modifier votre demande pendant qu'elle est en cours d'examen.",
      "review_action_activity": "{moderator} a demandé des modifications",
      "card_title": "S'il vous plaît noter"
    },
    "navigation": {
      "bio": "Bio",
      "experience": "Expérience",
      "connections": "Connexions"
    },
    "save_changes": "Sauvegarder les modifications"
  },
  "mentor_filter": {
    "panel": {
      "title": "Tous les mentors",
      "subtitle": "Utilisez les termes ci-dessous pour rationaliser davantage votre recherche.",
      "toggle_btn": "Filtrer par",
      "sort_by": "Trier par"
    },
    "labels": {
      "name": "Nom",
      "venture_type": "Type",
      "stage": "Phase d'investissement",
      "language": "Langues parlées",
      "sector": "Secteur",
      "country": "Basé à",
      "focus_locations": "Intéressé par",
      "topics": "Sujets"
    },
    "placeholders": {
      "name": "Recherche par nom...",
      "focus_locations": "Sélectionner la région ou le pays..."
    }
  },
  "mentor_request": {
    "page_title": "Demander un mentorat à",
    "page_subtitle": "Suivez les étapes ci-dessous pour postuler au mentorat.",
    "title_step_1": "Exigences",
    "title_step_2": "Détails de la demande",
    "title_step_3": "Présentation du mentor",
    "requirement_1": "Connectez-vous ou créez un compte VC4A pour continuer.",
    "requirement_1_success": "Vous êtes connecté",
    "requirement_2": "Vous avez besoin d'un profil d'entreprise pour demander un mentorat.",
    "requirement_3": "Avez-vous créé votre profil d’entreprise ? Rafraîchissez cette page pour continuer.",
    "select_venture": "Sélectionnez une entreprise et assurez-vous que le profil est à jour et qu'il contient un logo.",
    "mod_comments": {
      "alert": {
        "success": {
          "title": "Succès!",
          "message": "Les modifications de la demande de mentor ont été soumises avec succès pour révision."
        }
      },
      "cta_explanation": "Si vous avez mis à jour les modifications ci-dessus, vous pouvez soumettre à nouveau votre demande de révision avec le bouton ci-dessous."
    },
    "mod_controls": {
      "title": "Contrôles du modérateur",
      "btn_approve": "Approuver",
      "btn_change": "Requérir des modifications",
      "btn_deny": "Refuser",
      "btn_ban": "Interdiction",
      "confirm_delete": {
        "text_end": "Ceci ne peut pas être annulé",
        "button": "Supprimer définitivement",
        "mentee": {
          "title": "Supprimer définitivement le compte du mentoré?",
          "text": "Êtes-vous sûr de vouloir supprimer le compte du mentoré suivant?"
        },
        "mentor_request": {
          "title": "Supprimer définitivement la demande de mentor?",
          "text": "Êtes-vous sûr de vouloir supprimer la demande de mentor suivante?"
        }
      }
    },
    "statuses": {
      "pending": "En attente",
      "active": "Actif",
      "review": "Examen en cours",
      "closed": "Fermé",
      "moderation": "Modifications nécessaires",
      "rejected": "Rejeté",
      "denied": "Refusé",
    },
    "alts": {
      "venture_avatar": "Venture Avatar",
      "avatar": "Avatar d'étudiant"
    },
    "topic": {
      "label": "Quels sujets voulez-vous poser au mentor?",
      "hint": "Sélectionnez un minimum de 1 et un maximum de 3 sujets."
    },
    "request_title_label": "Titre de la demande",
    "intro_to_mentor": {
      "label": "Présentez-vous au mentor",
      "hint": "Parlez de vous et expliquez au mentor pourquoi vous pensez qu'il possède l'expérience/expertise que vous recherchez."
    },
    "problem": {
      "label": "Problème",
      "hint": "Expliquez le défi auquel vous êtes confronté. Quelles mesures avez-vous déjà prises pour tenter de relever ce défi ?"
    },
    "request": {
      "label": "Demande",
      "hint": "Expliquez le défi auquel vous faites face. Quelles mesures avez-vous prises pour essayer de relever ce défi."
    },
    "request_card": {
      "label": "Fait par",
      "cta": "Voir la demande"
    },
    "request_title_placeholder": "Recherche de mentorat pour améliorer le modèle d'entreprise",
    "ventures_not_allowed": "Les entreprises ci-dessous ne correspondent pas aux exigences du mentor.",
    "nav_menu" : {
      "agreement" : "Accord",
      "info" : "Demande d'informations",
      "feed" : "Discussions"
    }
  },
  "status_modal": {
    "success": {
      "mentor_profile": {
        "title": "Toutes nos félicitations",
        "heading": "Votre profil de mentor a été créé avec succès!",
        "content": "Veuillez noter que votre profil passera par un processus de vérification et de modération avant d'être rendu public et que vous pourrez alors commencer à recevoir des demandes de mentorés.",
        "button": "Voir mon profil"
      },
      "mentor_request": {
        "title": "Toutes nos félicitations",
        "heading": "Votre demande de mentor a été créée avec succès!",
        "content": "Veuillez noter que votre demande de mentorat sera soumise à un processus de vérification et de modération avant d'être transmise au mentor de votre choix.",
        "button": "Voir ma demande de mentor"
      },
      "mentor_request_update": {
        "heading": "Votre demande de mentor a été mise à jour avec succès!"
      }
    }
  },
  "select_placeholder": "-- sélectionnez --",
  "select_placeholder_multiple": "-- sélectionnez --",
  "full_name": "Nom complet",
  "email_address": "Courrier électronique",
  "city": "Ville",
  "country": "Pays",
  "about": "A propos",
  "linkedin_profile": "Profil LinkedIn",
  "see_example": "Voir l'exemple",
  "submit": "Soumettre",
  "settings": "Paramètres",
  "sector": "Secteur",
  "focus_country": "Pays de concentration",
  "topic": "Sujet",
  "interest": "Intérêts",
  "venture_stage": "Etape Venture",
  "options_selected": "Options sélectionnées",
  "avatar": "Avatar",
  "spoken_language": "Langue parlée",
  "geo_focus": "La priorité géographique",
  "mentor_type": "Type de mentor",
  "venture_type": "Etape Venture",
  "monthly_rate": "Taux mensuel",
  "get_started": "Lancet le démarrage",
  "vc4a_account": "Compte VC4A",
  "venture_profile": "Profil de risque",
  "add_venture": "Ajouter une entreprise",
  "other": "Autres",
  "back": "Retour",
  "venture": "Entreprise",
  "mentor": "Mentor",
  "entrepreneur": "Entrepreneur",
  "you": "Toi",
  "validation": {
    "required": "est obligatoire.",
    "url": "n'est pas une URL valide",
    "invalid": "n'est pas valide",
    "integer": "doit être un entier",
    "between": "doit être une valeur entre",
    "too_short": "C'est trop court.",
    "min_length": "doit contenir au moins",
    "characters": "caractères",
    "default_name": "Ce champ"
  },
  "error_message": {
    "city": "Le champ ville est obligatoire",
    "country": "Le champ Pays est obligatoire",
    "linkedin_profile": "Le champ du profil LinkedIn est obligatoire",
    "about": "Le champ À propos est obligatoire",
    "spoken_languages": "Le champ Langues parlées est obligatoire",
    "mentor_type": "La zone de type Mentor est obligatoire",
    "sectors": "La zone Secteurs est obligatoire",
    "topic": "La zone de rubrique est obligatoire",
    "mentor_motivation": {
      "why": "La motivation du mentor est obligatoire."
    },
    "mentor_request": {
      "alert_title": "Oups. Veuillez corriger les erreurs dans le formulaire.",
      "sign_in": "Connectez-vous ou créez un compte VC4A pour continuer.",
      "no_venture_profile": "Vous devez avoir un profil d'entreprise pour demander un mentorat.",
      "select_venture_profile": "Vous devez sélectionner une entreprise pour continuer.",
      "your_venture": "Votre entreprise",
      "venture_req": "doit avoir un score de 100%, veuillez mettre à jour votre entreprise pour continuer.",
      "update_venture": "Mettez votre entreprise à jour"
    }
  },
  "single_request": {
    "back_btn": {
      "mentee": "Retour à la page des mentors",
      "mentor": "Retour aux demandes"
    },
    "alert": {
      "pending": {
        "title": "En attente de révision",
        "message": "Votre demande a été soumise pour révision. Vous pouvez toujours modifier votre demande alors qu'elle est en attente de révision."
      },
      "approved": {
        "title": "Approuvé",
        "message": "Votre demande a été approuvée par VC4A et attend maintenant une réponse du mentor."
      },
      "accepted": {
        "title": "Accepté",
        "message": "Votre demande a été acceptée par le mentor."
      }
    },
    "request_content": "Contenu de la demande",
    "introduction": "A. Introduction",
    "problem": "Problème",
    "request": "Demande",
    "topics": "Sujets",
    "linked_venture": "Entreprise associée",
    "view_venture_profile": "Afficher le profil de risque",
    "view_account": "Afficher le compte",
    "view_mentee_profile": "Afficher le profil de l'entrepreneur",
    "view_mentor_profile": "Afficher le profil du mentor",
    "from": "De",
    "to": "Vers",
    "action_panel_hint": "Vous ne pouvez voir que cette",
    "modal": {
      "accept_request": {
        "action": "Accepter la demande",
        "confirmation": "Etes-vous sûr de vouloir accepter la demande"
      },
      "reject_request": {
        "action": "Rejeter la demande",
        "confirmation": "Etes-vous sûr de vouloir rejeter la demande",
        "text_note": "Cette information sera partagée avec le mentoré.",
        "no_time": "Je n'ai pas le temps en ce moment",
        "no_expertise": "Je ne peux pas apporter la bonne expertise",
        "not_clear": "La demande de mentorat n'est pas assez claire",
        "other": "Autre"
      },
      "close_request": {
        "action": "Demande de fermeture",
        "confirmation": "Etes-vous sûr de vouloir fermer la demande?"
      },
      "edit_request": {
        "action": "Editer la demande"
      },
      "private_note": {
        "action": "Ajouter une note privée",
        "placeholder": "Ecrire ici ...",
        "button_text": "Enregistrer la note"
      },
      "report": {
        "action": "Rapport",
        "confirmation": "Aidez-nous à comprendre le problème. Qu'est-ce qui se passe avec cette demande?"
      }
    },
    "reason": "Raison",
    "close_request": {
      "title": "Fermer cette demande?",
      "action": "Oui, demande ferme"
    },
    "mentorship_info": {
      "title": "Informations sur le mentorat"
    },
    "page_title" : "Connexion au mentorat",
    "page_hint" : "Entre {mentor} (Mentor) et {mentee} (Mentoré).",
    "manage_mentorship_btn" : "Gérer le mentorat",
    "help_btn" : "Voir l'aide",
    "discussions" : "Discussions",
    "help_modal" : {
      "title" : "Directives pour votre mentorat",
      "learn_more_btn_text" : "En savoir plus sur les connexions de mentorat"
    },
    "agreement_help_modal" : {
      "title" : "Lignes directrices pour votre entente de mentorat",
      "learn_more_btn_text" : "En savoir plus sur les ententes de mentorat"
    }
  },
  "status": {
    "status": "Statut",
    "pending_review": "Révision en attente",
    "review": "Révision en attente",
    "approved": "Approuvé",
    "accepted": "Accepté",
    "rejected": "Refusé",
    "active": "Actif",
    "inactive": "Inactif",
    "publish": "Publié",
    "moderation": "En cours de révision",
    "closed": "Fermé",
    "pending_inactive": "Fermé",
    "completed": "Complété",
    "unlisted": "Publié (non listé)",
    "unavailable": "Indisponible",
    "private": "Caché",
    "denied": "Refusé",
    "draft_description": "Votre demande est en projet",
    "moderation_description": "Votre demande a été examinée et nécessite des modifications.",
    "pending_review_description": "Votre demande est en cours d'examen.",
    "pending_acceptance_description": "Votre demande est en cours d'examen.",
    "pending_inactive_description": "Votre demande a été fermée pour cause d'inactivité.",
    "inactive_description": "Votre demande a été fermée pour cause d'inactivité.",
    "approved_description": "Cette connexion de mentorat est active.",
    "rejected_description": "Votre demande a été refusée.",
    "closed_description": "Cette demande a été fermée.",
    "completed_description": "Cette demande a été fermée."
  },
  "from": "à partir de",
  "to": "Vers",
  "of": "de",
  "mentorship_feed": {
    "title": "Votre flux de tutorat",
    "progress": "Progrès",
    "items_done": "éléments effectués",
    "show_more_activity": "Chargez plus",
    "no_activity": {
      "part_1": "Commencez une conversation.",
      "part_2": "Lancez le mentorat en proposant une date/heure et un lien de communication/numéro pour se rencontrer pour la première fois."
    },
    "comment_button": "Laisser un commentaire"
  },
  "mentor_request_index": {
    "page_title": "Demandes de mentorat pour vous",
    "page_subtitle": "Vous trouverez ici les demandes qui vous ont été envoyées.",
    "navigation": {
      "pending": "En attente",
      "active": "Actif",
      "history": "Historique"
    },
    "empty_results": "Vous n'avez aucune demande",
    "request_count": "Vous avez {count} demande de mentorat | Vous avez {count} demandes de mentorat",
    "request_card": {
      "request_date": "Date de la demande",
      "cta_text": "Voir le mentorat",
      "mentee_hint": "Étudiant",
      "venture_profile_hint": "Profil d'entreprise lié"
    },
    "see_more_btn_text": "Voir plus de demandes"
  },
  "manage_profile": {
    "title": "Gérer votre profil",
    "hint": "Seulement vous pouvez voir ça.",
    "moderation_comment": "Commentaire(s) de l'équipe VC4A",
    "resubmit_text": "Veuillez effectuer les ajustements nécessaires en cliquant sur le bouton Modifier le profil ci-dessous et soumettre à nouveau votre profil en enregistrant vos modifications.",
    "status_description": {
      "publish": "Votre profil a été approuvé par l'équipe et est maintenant répertorié.",
      "moderation": "L'équipe VC4A a des commentaires sur votre profil, vérifiez le(s) commentaire(s) ci-dessus et modifiez votre profil.",
      "review": "Votre profil est actuellement en attente de révision par le modérateur.",
      "unlisted": "Votre profil est publié et actif, mais n'est pas encore accessible au public.",
      "unavailable": "Vous avez configuré votre profil pour qu'il soit indisponible, vous pouvez modifier la disponibilité via la page des paramètres.",
      "private": "Votre profil a été défini comme privé et n'est pas visible pour les autres utilisateurs."
    },
    "moderation_title": "Modérée",
    "moderation_approve": "Approuver",
    "moderation_approve_unlisted": "Approuver (non listé)",
    "moderation_changes": "Requérir des modifications",
    "moderation_deny": "Refuser",
    "moderation_ban": "Interdiction",
    "requests_title": "Demandes",
    "pending_requests_unit": "{count} demande en attente | {count} demande en attente",
    "matched_requests_unit": "{count} demandes assortie | {count} demandes assorties",
    "pending_requests_cta": "Vous avez des demandes en attente. Cliquez pour les visualiser.",
    "moderation_comment_title": "Commentaires sur la modération",
    "moderation_comment_placeholder": "Entrez les changements demandés ici...",
    "moderation_deny_reason_placeholder": "Optionnel : refuser la raison..."
  },
  "actions": "Actions",
  "profile_settings": {
    "page_subtitle": "Voici les paramètres du profil de votre mentor.",
    "mentorship_settings": "Paramètres de mentorat",
    "availability_label": "Êtes-vous disponible?",
    "active_connections_label": "Combien de connexions actives à la fois?",
    "pending_connections_label": "Combien de connexions en attente à la fois?",
    "email_notifications": "Notifications par courrier électronique",
    "update_notifications": "Mise à jour des préférences de notification",
    "allow_connection_label": "Autorisez UNIQUEMENT les connexions d'entrepreneurs qui:",
    "match_selected_ventures": "Correspondre à ma ou mes étapes d'aventure sélectionnées",
    "match_selected_sectors": "Correspondre au(x) secteur(s) sélectionné(s)",
    "match_focus_locations": "Faire correspondre mes emplacements de mise au point sélectionnés",
    "available" : "Je suis actuellement disponible pour prendre des connexions",
    "unlisted_available" : "Je suis disponible pour prendre des contacts avec quiconque sur le marché du mentorat (votre profil sera examiné par les modérateurs)",
    "unlisted" : "Je ne suis actuellement disponible que pour un ou plusieurs programmes spécifiques",
    "unavailable" : "Je suis actuellement indisponible",
    "private" : "Je suis actuellement indisponible et je cache mon profil",
    "delete_profile_title": "Supprimer le profil du mentor",
    "delete_profile_desc": "Voulez-vous supprimer votre profil de mentor ? Cette action est permanente et ne peut pas être annulée.",
    "delete_profile_cta": "Supprimer votre profil de mentor",
    "delete_profile_modal": {
      "alert_message": "Si vous ne lisez pas ceci, de mauvaises choses inattendues vont se produire",
      "confirmation_text_1": "Êtes-vous sûr de vouloir supprimer votre profil de mentor VC4A",
      "confirmation_text_2": "Ceci est une action permanente et ne peut être annulée. Cette action supprime :",
      "list_item_1": "toutes les informations du profil",
      "list_item_2": "toutes les demandes attachées à votre profil",
      "password_label": "Veuillez entrer votre mot de passe pour confirmer",
      "cta": "Supprimer ce profil de mentor",
    },
    "delete_profile_success": "Votre profil de mentor a été supprimé avec succès.",
    "max_connections_placeholder": "Nombre entre 1 et 10"
  },
  "sort": {
    "date_asc": "Date (la plus ancienne en premier)",
    "date_desc": "Date (le plus récent en premier)",
    "name_asc": "Nom (A-Z)",
    "name_desc": "Nom (Z-A)"
  },
  "in": "Dans",
  "role": "Rôle",
  "organization": "Organisation",
  "feedback": "Commentaires en retour",
  "review": "Révision",
  "close_request": {
    "page_title": "Demande de fermeture",
    "feedback_question": "Comment le mentorat a-il été fait?",
    "write_a_review": "Rédiger un examen",
    "rate": "Taux",
    "use_review": "Laissez-nous utiliser cet examen sur le profil du mentor.",
    "as_mentor": {
      "review_hint": "Évaluez votre expérience de votre mentoré dans cette demande."
    },
    "as_mentee": {
      "review_hint": "Évaluez votre expérience de votre mentor dans cette demande."
    }
  },
  "star_rating": {
    "1": "Médiocre",
    "2": "Passable",
    "3": "Bonne",
    "4": "Très bien",
    "5": "Excellent",
    "default": "Touter une étoile au taux"
  },
  "rating": "Evaluation",
  "dismiss": "Démande",
  "activity": {
    "title": "Activité"
  },
  "contact_form": {
    "title": "Contactez-nous",
    "description": "Remplissez le formulaire ci-dessous et un représentant VC4A communiquera avec vous dès que possible.",
    "send_message": "Envoyer message",
    "success_message": "Message envoyé avec succès",
    "message_label": "Message"
  },
  "message": "Message",
  "mentorship_agreement": {
    "page_title": "Entente de mentorat",
    "start_date": "Date de début du mentorat",
    "duration": "Durée du mentorat",
    "frequency": "Fréquence des réunions",
    "frequency_meta": "{count} par {unit}",
    "challenges": "Défis",
    "challenges_identified": "Défis identifiés",
    "add_challenge": "Ajouter un nouveau défi",
    "closed_agreement_hint": "Aucun accord n'a été trouvé pour cette connexion de mentorat",
    "goals": "Objectifs",
    "mentorship_goals": "Objectifs de mentorat",
    "add_goal": "Ajouter un nouvel objectif",
    "submit_btn": "Soumettre un contrat",
    "tab_title": "Accord",
    "agreement_hint": "C'est une exigence pour le mentoré et le mentor. Il aide les deux parties à établir un contrat défini.",
    "create_agreement": "Créer un accord de mentorat",
    "create_agreement_btn": "Créer un accord",
    "help_btn": "Qu'est-ce que c'est ?",
    "note_title": "Note",
    "edit_btn_text": "Modifier l'accord",
    "back_btn_text": "Retour à la demande de mentor"
  },
  "meeting_frequency" : {
    "1" : "Une réunion",
    "2" : "Deux réunions",
    "3" : "Trois réunions",
    "4" : "Quatre réunions",
    "5" : "Cinq réunions",
    "6" : "Six réunions",
    "7" : "Sept réunions",
    "8" : "Huit réunions",
    "9" : "Neuf réunions"
  },
  "mentee": "Mentoré",
  "moderator": "Modérateur",
  "connection_details": {
    "title": "Détails",
    "request_date": "Date de la demande"
  },
  "select_all": "Sélectionner tout",
  "meta": {
    "deleted_user_name": "utilisateur supprimé",
  },
}
