<template>
    <div
        role="radiogroup"
        :aria-labelledby="label ? legendId : null"
    >
        <div
            v-if="label"
            :id="legendId"
            class="text--sm mb-3"
        >
            {{ label }}
        </div>
        <div
            class="vc4a-radio__label-wrapper"
            :class="[
                'vc4a-radio__label-wrapper',
                {'vc4a-radio__label-wrapper--bordered': isBordered}
            ]"
        >
            <label
                v-for="(radioDataObj, index) in radioDataArr"
                class="vc4a-radio"
                :class="getVariantClassesStr(index)"
                :key="'vc4a-radio-' + index"
            >
                <!-- Hidden component part: -->
                <input
                    :name="name"
                    type="radio"
                    :value="radioDataObj.value"
                    v-model="localModelValue"
                    :disabled="isDisabled"
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                    :aria-invalid="errorText ? true : null"
                    :aria-describedby="errorText ? errorHintId : null"
                    @change="handleChange($event)"
                />
                
                <!-- Visible component parts: -->
                <span class="vc4a-radio__radio"></span>
                <span class="vc4a-radio__text-wrap">
            <span class="vc4a-radio__label-title">
                {{ radioDataObj.labelTitleStr }}
            </span>
            <span
                v-if="radioDataObj.labelDescStr"
                class="vc4a-radio__label-description"
            >
                {{ radioDataObj.labelDescStr }}
            </span>
        </span>
            </label>
        </div>
        <div
            v-if="errorText"
            :id="errorHintId || null"
            class="vc4a-radio__error-text"
            :role="errorText ? 'alert' : null"
        >
            {{ errorText }}
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Vc4aRadio',
    props: {
        radioDataArr: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [ String, Number ], // Adds support for v-model
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isBordered: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
        },
        id: {
            type: String,
            //randomly generates a key from 0 to 10,000
            default: () => {
                return 'radio-' + Math.floor(Math.random() * 10000);
            },
        },
        label: {
            type: String,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isFocused: false,
            localModelValue: null
        };
    },
    mounted() {
        this.localModelValue = this.modelValue;
    },
    methods: {
        handleChange($event) {
            this.$emit( 'update:modelValue', $event.target.value )
        },
        getVariantClassesStr(index) {
            let returnableStr = '';

            if (this.isDisabled) {
                returnableStr += 'vc4a-radio--disabled ';
            }

            if (this.modelValue === this.radioDataArr[index].value) {
                returnableStr += 'vc4a-radio--checked ';
            }

            if (this.isBordered) {
                returnableStr += 'vc4a-radio--bordered ';
            }

            if (this.radioDataArr[index].isFocused) {
                returnableStr += 'vc4a-radio--focused ';
            }

            return returnableStr;
        },
    },
    computed: {
        errorHintId() {
            return this.$props.id + '-error';
        },
        legendId() {
            return this.$props.id + '-legend';
        }
    },
    watch: {
        modelValue(newVal) {
            this.localModelValue = newVal;
        }
    }
};
</script>
