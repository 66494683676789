<template>
    <Vc4aRadio
        name="development-stage"
        :label="$t('initial_estimate.form.dev_stage')"
        :isBordered="true"
        :radioDataArr="devStageOptions"
        :modelValue="devStage"
        @update:modelValue="$emit('update:dev-stage', $event)"
        :error-text="getErrorMessage('devStage')"
    
    />
</template>

<script>
import Vc4aRadio from "@stylesComponents/vue/vc4a-radio.vue"
import { useVuelidate } from "@vuelidate/core"
import { required, getErrorMessage } from '@stylesComponents/js/i18n-validator';

export default {
    name: 'InitialEstimateStep-2',
    components: {
        Vc4aRadio
    },
    props: {
        devStage: {
            type: String
        },
        devStageOptions: Array
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    emits: ['update:dev-stage'],
    validations() {
        return {
            devStage: { required }
        }
    },
    methods: {
        getErrorMessage
    }
}
</script>
