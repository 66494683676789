<template>
    <!-- TODO: make this actually send emails -->
    <modal-basic 
        :visible-overflow="true"
        :load-as-hidden-render="true"
        max-width="32rem"
        ref="cohort-email-member-modal"
    >
        <!-- Header -->
        <template #title>
            <!-- "Email member" -->
            {{ $t('cohorts.settings.messaging.modal_title') }}
        </template>

        <!-- CTA -->
        <template #toggle-btn>
            <button class="btn btn--secondary btn--sm">
                <!-- "Send group email" -->
                {{  $t('email.cta_group') }}
            </button>
        </template>

        <!-- Modal Content -->
        <form 
            id="cohort-email-settings" 
            class="cohort-email-edit"
        >
            <label for="email-recipient">
                <!-- "Recipient(s):" -->
                {{ $t('email.recipients', recipientIdsArr.length ) }}
            </label>

            <input
                class="form-field mb-5"
                type="text"
                name="email-recipient"
                v-model="recipientNameVar"
                id="email-recipient"
                disabled
            >
            
            <label for="email-subject">
                <!-- "Subject" -->
                {{ $t('email.subject') }}
            </label>
            <input
                class="form-field mb-5"
                type="text"
                name="email-subject"
                id="email-subject"
                v-model="emailSubject"
            >
            
            <label for="email-body">
                <!-- "Body" -->
                {{ $t('email.body') }}
            </label>
            <textarea
                class="form-field mb-5"
                name="email-body"
                id="email-body"
                rows="3"
                v-model="emailBody"
            ></textarea>
    
            <!-- Buttons -->
            <div class="cohort-email-edit__button-row">
                <div 
                    class="btn btn--secondary"
                    @click="handleCancelBtn()"
                >
                    <!-- "Cancel" -->
                    {{ $t('common.ctas.cancel') }}
                </div>
        
                <div 
                    class="btn btn--primary"
                    @click="handleSubmitForm()"
                >
                    <!-- "Send email" -->
                    {{ $t('email.cta')  }}
                </div>
            </div>
        </form>
    </modal-basic>
</template>

<script>

import i18n from "@/i18n";

import ModalBasic from '$globalsrc/modal/modal-basic';

export default {
    name: "CohortEmailMember", 
    components: {
        ModalBasic,
    },  
    props: {
        nonce: {
            type: String,
            required: true
        },
        cohortId: {
            type: Number,
            required: true
        },
        recipientName: { // Can be a group name for group-email
            type: String,
            required: true,
        },
        recipientIdsArrJson: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            queryInProgress: false,
            emailSubject: '',
            emailBody: '',
            emailRecipients: JSON.parse(this.recipientIdsArrJson),
            recipientIdsArr: JSON.parse(this.recipientIdsArrJson),
            recipientNameVar: this.recipientName, // so the prop can be used in the vmodel
        }
    },
    methods: {
        handleCancelBtn() {
            this.$refs[`cohort-email-member-modal`].closeModal();
        },
        async handleSubmitForm() {
            this.queryInProgress = true;

            const paramsObj = {
                "_nonce": this.nonce,
                "email-recipients": this.$data.emailRecipients,
                "email-subject": this.$data.emailSubject,
                "email-body": this.$data.emailBody,
                "cohort_id": this.cohortId
            }

            await fetch(
                ajaxurl + '?action=cohort_messaging&' + new URLSearchParams(paramsObj).toString(),
                {
                    method: 'POST',
                }
            ).then((response) => {
                if (response.status > 200) {
                    response.json().then((json) => {
                        this.queryInProgress = false;
                    });
                    return;
                }

                this.queryInProgress = false;
                this.showFlash('success');
            }).catch((error) => {
                console.error(error);
                this.showFlash('failure');
                this.queryInProgress = false;
            })

            this.$refs[`cohort-email-member-modal`].closeModal();
        },
    },
}
</script>
