<template>
    <div v-if="componentIsValid">
        <div 
            v-show="showModal || !isModal"
            :class="isModal ? 'youtube-embed--modal' : 'youtube-embed'"
        >
            <div
                v-show="showIframe || showModal"
                class="youtube-embed__iframe-wrap"
            >
                <iframe 
                    :src="sanitisedEmbedUrl" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                <div 
                    class="youtube-embed__close-btn"
                    @click="closeModal()"
                >
                    <vc4a-icon name="close"/>
                </div>
            </div>
            <div 
                class="youtube-embed__overlay"
                v-if="showModal && isModal"
                @click="closeModal()"
            ></div>
        </div>
        <!-- Play button -->
        <div 
            v-if="isModal"
            class="youtube-embed__modal-toggle btn btn--play"
            :class="buttonVariantClassesStr + ' ' + buttonVisibilityClassStr"
            @click="openModal()"
        >
            <vc4a-icon name="arrow-right" class-name="w-4 h-4"/>
        </div>
    </div>
</template>

<script>
import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "YoutubeEmbed",
    components: {
        Vc4aIcon,
    },
    props: {
        embedUrlStr: {
            type: String,
            default: '',
        },
        isModal: {
            type: Boolean,
            default: true,
        },
        buttonStyle: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            componentIsValid: false,
            sanitisedEmbedUrl: '',
            showIframe: false,
            showModal: false,
        }
    },
    methods: {
        setSanitisedEmbedUrl() {
            let urlStr = this.embedUrlStr || '';

            // Swap youtube watch url for embed url
            urlStr = urlStr.replace('/watch?v=', '/embed/');
            // Swap short url for embed url
            urlStr = urlStr.replace('youtu.be/', 'www.youtube.com/embed/');

            if ( urlStr.includes('/embed/') ) {
                this.componentIsValid = true;
                this.sanitisedEmbedUrl = urlStr;
            }
        },
        openModal() {
            if (!this.showModal && this.isModal) {
                document.querySelector('body').classList.add('scroll-is-locked');
                this.showModal = true;
                this.showIframe = true;
            }
        },
        closeModal() {
            if (this.showModal && this.isModal) {
                document.querySelector('body').classList.remove('scroll-is-locked');
                this.showModal = false;
                this.showIframe = false;
            }
        }
    },
    computed: {
        buttonVariantClassesStr() {
            if (this.buttonStyle) {
                return 'btn--' + this.buttonStyle;
            }

            return '';
        },
        buttonVisibilityClassStr() {
            if (this.showModal) {
                // hidden and non-interactive but still takes up space 
                return 'youtube-embed__toggle--hidden'; 
            }

            return '';
        }
    },
    mounted() {
        this.setSanitisedEmbedUrl();

        if (!this.isModal) {
            this.showModal = false;
            this.showIframe = true;
        }

        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.closeModal();
            }
        })
    },
}
</script>
