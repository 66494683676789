<!--Modal
    This component adds a modal to the DOM but hidden by default
    
    HOW TO USE:
    
    <modal title="Title name goes here" show="toggleVisibility">
        Modal content goes here
    </modal>
    
    PROPS:
        title:  Modal title
        show:   toggles modal visibility
        id:     gives the modal an ID
        
    Clicking the close icon emits a "close" event
-->


<template>
    <div :class="['modal', {'is-active': show}, this.modifierClass]">
        <div class="modal__wrap" :id="id">
            <div @click.prevent="closeModal" class="modal__bg-close"></div>
            <div class="modal__content">
                <div class="modal__header">
                    <h6 class="modal__title"><slot name="title">{{ title }}</slot></h6>
                    <button class="modal__close" @click.prevent="closeModal">
                        <icon name="close"></icon>
                    </button>
                </div>
                <div class="modal__body">
                    <slot name="content"></slot>
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            id: String,
            title: String,
            show: {
                type: Boolean,
                default: false
            },
            modifierClass: String
        },
        
        methods: {
            closeModal: function(event){
                this.$emit('close');
            }
        }
    };
</script>
