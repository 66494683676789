<template>
    <div class="empty-state">
        <vc4a-icon :name="icon" class="empty-state__icon"/>
        <div class="label text-secondary">{{ title }}</div>
        <div class="paragraph-sm text-tertiary mb-0">{{ desc }}</div>
    </div>
</template>

<script>
import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "empty-state",
    components: {
        Vc4aIcon
    },
    props: {
        icon: String,
        title: String,
        desc: String
    }
}
</script>
