// DATETIME FORMATS EXAMPLES
//   short - Oct 2, 2023
//   long  - October 2, 2023
//   full  - Mon, October 2, 2023 at 10:59 AM

const datetimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        full: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'es': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric',
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        full: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'fr': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        full: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
}

export default datetimeFormats
