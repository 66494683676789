<!-- 
    Component for editing cohort email settings.
    Each component represents a different setting.
    Contents: 1x modal-basic component, using a button to open the modal

    const my_data_obj = {
        'title': 'The name/title of the Program',
        'id': 000000
    }

    <cohort-email-edit
        email-type="my_slug'"
        modal-title="My Top-level Title"
        :program-data-obj="my_data_obj"
    ></cohort-email-edit>
 -->

<template>
    <modal-basic
        ref="cohort-settings-email-modal"
    >
        <!-- Modal title -->
        <template #title>
            {{ modalTitle }}
        </template>
        
        <!-- Modal Content -->
        <form 
            id="cohort-email-settings" 
            class="cohort-email-edit"
        >
            <label for="email-sender">
                <!-- "Sender" -->
                {{ $t('email.sender') }}
            </label>
            <!-- TODO: make sure this field is getting a valid program name to fill it (once BE integrated) -->
            <input
                class="form-field mb-5"
                type="text"
                name="email-sender"
                id="email-sender"
                v-model="programDataObj.title"
                disabled
            >
            
            <label for="email-subject">
                <!-- "Subject" -->
                {{ $t('email.subject') }}
            </label>
            <input
                class="form-field mb-5"
                type="text"
                name="email-subject"
                id="email-subject"
                v-model="emailTitleStr"
            >
            
            <label for="email-body">
                <!-- "Body" -->
                {{ $t('email.body') }}
            </label>
            <textarea
                class="form-field mb-5"
                name="email-body"
                id="email-body"
                rows="3"
                v-model="emailBodyStr"
            ></textarea>
    
            <!-- Buttons -->
            <div class="cohort-email-edit__button-row">
                <div 
                    class="btn btn--secondary"
                    @click="handleCancelBtn()"
                >
                    <!-- "Cancel" -->
                    {{ $t('common.ctas.cancel') }}
                </div>
        
                <div 
                    class="btn btn--primary"
                    @click="handleSubmitForm()"
                >
                    <!-- "Save changes" -->
                    {{ $t('common.ctas.save_changes') }}
                </div>
            </div>
        </form>

        <!-- CTA (toggles modal) -->
        <template #toggle-btn>
            <div class="btn btn--primary btn--sm mt-5">
                <!-- "Edit email" -->
                {{ $t('cohorts.settings.messaging.edit_email') }}
            </div>
        </template>

    </modal-basic>
</template>

<script>

import i18n from "@/i18n";

import ModalBasic from '$globalsrc/modal/modal-basic';

export default {
    name: "CohortEmailEdit", 
    components: {
        ModalBasic,
    },  
    props: {
        emailType: {
            type: String,
            required: true
        },
        modalTitle: {
            type: String,
            required: true
        },
        programDataObj: { // contains 'title' and 'id'
            type: Object,
            default() {
                return {
                    'title': '',
                    'id': 0
                }
            }
        }
    },
    data() {
        return {
            emailTitleStr: '',
            emailBodyStr: '',
        }
    },
    methods: {
        handleCancelBtn() { // needed to close modal from outside modal component
            this.$refs[`cohort-settings-email-modal`].closeModal();
        },
        handleSubmitForm() {
            // TODO:
            // handle submission here

            // use this.programDataObj.id for the program ID
            // use this.emailTitleStr for the email title value 
            // use this.emailBodyStr for the email body value 
            // use this.emailType prop to define which email is being edited
        }
    },
}
</script>
