<template>
    <div class="vc4a-carousel" v-if="slotIdsArr.length">
        <template v-if="slotIdsArr.length > 1">
            <transition-group 
                class="slides-group"
                tag="div" 
                :name="transitionName" 
            >
                <div v-if="show" :key="current" class="slide" :class="slides[current].slotId">
                    <slot :name="`id_${slides[current].slotId}`"></slot>
                </div>
            </transition-group>
            <div class="vc4a-carousel__dots">
                <div
                    v-for="(slot, index) in slotIdsArr"
                    :key="`dot_${slot}`"
                    :class="current === index ? 'vc4a-carousel__dot--active' : 'vc4a-carousel__dot'"
                >
                </div>
            </div>
            <div class="vc4a-carousel__btn-wrap">
                <div class="vc4a-carousel__btn-wrap-inner container">
                    <div 
                        class="vc4a-carousel__btn--prev btn btn--icon"
                        aria-label="Previous slide"
                        @click="slide(-1)"
                    >
                        <vc4a-icon name="chevron-left" />
                    </div>
                    <div
                        class="vc4a-carousel__btn--next btn btn--icon"
                        aria-label="Next slide"
                        @click="slide(1)"
                    >
                        <vc4a-icon name="chevron-right" />
                    </div>
                </div>
            </div>
            <!-- This is to keep a constant size for the component, as the dynamic content is position:absolute -->
            <div class="vc4a-carousel__item--hidden"><slot :name="`id_${slotIdsArr[0]}`"></slot></div>
        </template>

        <template v-else>
            <slot :name="`id_${slotIdsArr[0]}`"></slot>
        </template>
    </div>
</template>


<script>


import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "Vc4aCarousel",
    components: {
        Vc4aIcon
    },
    props: {
        slotIdsJson: {
            type: [ String, Array ],
            default: '[]',
        },
    },
    data(){
        return {
            slotIdsArr: JSON.parse(this.slotIdsJson),
            current: 0,
            direction: 1,
            transitionName: "fade",
            show: false,
            slides: []
        }
    },
    methods: {
        slide(dir) {
            this.direction = dir;
            dir === 1
                ? (this.transitionName = "slide-next")
                : (this.transitionName = "slide-prev");
            var len = this.slides.length;
            this.current = (this.current + dir % len + len) % len;
        },

        buildSlideSlotArray() {
            this.slides = [];
            this.slotIdsArr.forEach(slotId => {
                this.slides.push({ 'slotId': slotId });
            });
        },
    },
    computed: {

    },
    mounted() {
        this.buildSlideSlotArray();
        this.show = true;
    },
}
</script>
