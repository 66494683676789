<template>
    <div class="eq-estimate">
        <EqModal
            @close:eq-modal="$emit('close:eq-modal')"
        >
            <div class="container container--sm">
                <template v-if="!showEstimateResults">
                    <div class="eq-estimate__heading">
                        <h3 class="eq-estimate__title">{{ $t('initial_estimate.heading.title') }}</h3>
                        <div class="text--sm">
                            {{ $t('initial_estimate.heading.description') }}
                        </div>
                    </div>
                    <Vc4aProgressIndicator
                        :current-section="currentSection"
                        :sections-arr="formSectionsArr"
                        class="mb-7"
                    />
                    <div class="card">
                        <div class="card__body">
                            <template v-if="currentSection === 0">
                                <InitialEstimateStep1
                                    ref="initial-estimate-0"
                                    v-model:founders-number="localFormData.foundersnumber"
                                    v-model:employees-number="localFormData.employeesnumber"
                                    v-model:started-year="localFormData.startedyear"
                                    v-model:country="localFormData.country"
                                    v-model:industry="localFormData.industry"
                                    :ventureName="ventureName"
                                    :country-options="convertObjToArr(countryFormOptions)"
                                    :industry-options="convertObjToArr(industryFormOptions)"
                                />
                            </template>
                            
                            <template v-else-if="currentSection === 1">
                                <InitialEstimateStep2
                                    ref="initial-estimate-1"
                                    v-model:dev-stage="localFormData.devstage"
                                    :dev-stage-options="devStageOptions"
                                />
                            </template>
                            
                            <template v-else-if="currentSection === 2">
                                <InitialEstimateStep3
                                    ref="initial-estimate-2"
                                    v-model:iptype="localFormData.iptype"
                                    v-model:profitability="localFormData.profitability"
                                    v-model:estimated-revenue-y1="localFormData.estimatedrevenues_y1"
                                    v-model:estimated-revenue-y4="localFormData.estimatedrevenues_y4"
                                    :ventureName="ventureName"
                                    :iptype-options="intellectualPropertyOptions"
                                    :profitability-options="profitabilityOptions"
                                />
                            </template>
                            
                            <!-- Form controls -->
                            <div class="flex gap-1 mt-6">
                                <button
                                    v-if="currentSection > 0"
                                    class="vc4a-btn vc4a-btn--secondary"
                                    @click="changeFormStep('prev')"
                                    :disabled="queryInProgress"
                                >
                                    {{ $t('initial_estimate.controls.back') }}
                                </button>
                                <button
                                    v-if="formHasNextStep"
                                    class="vc4a-btn vc4a-btn--primary"
                                    @click="changeFormStep('next')"
                                    :disabled="queryInProgress"
                                >
                                    {{ $t('initial_estimate.controls.continue') }}
                                </button>
                                <button
                                    v-else
                                    class="vc4a-btn vc4a-btn--primary"
                                    @click="changeFormStep('next')"
                                    :disabled="queryInProgress"
                                >
                                    {{ $t('initial_estimate.controls.cta') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <InitialEstimateResult
                        :venture-name="ventureName"
                        :has-submit-error="hasSubmitError"
                        :query-in-progress="queryInProgress"
                        :low-bound-valuation="businessValuationDataObj.lowValuationCompact"
                        :high-bound-valuation="businessValuationDataObj.highValuationCompact"
                        @close:eq-modal="$emit('close:eq-modal')"
                        @update:return-to-form="$emit('update:return-to-form')"
                    />
                </template>
            </div>
        </EqModal>
    </div>
</template>

<script>
import i18n from '@/i18n';
import EqModal from '@/views/equidam/eq-modal.vue';
import Vc4aProgressIndicator from "@stylesComponents/vue/vc4a-progress-indicator.vue"
import InitialEstimateStep1 from '@/views/equidam/parts/initial-estimate-step-1.vue';
import InitialEstimateStep2 from '@/views/equidam/parts/initial-estimate-step-2.vue';
import InitialEstimateStep3 from '@/views/equidam/parts/initial-estimate-step-3.vue';
import InitialEstimateResult from '@/views/equidam/parts/initial-estimate-results.vue';
export default {
    name: 'InitialEstimateFlow',
    components: {
        EqModal,
        Vc4aProgressIndicator,
        InitialEstimateStep1,
        InitialEstimateStep2,
        InitialEstimateStep3,
        InitialEstimateResult
    },
    props: {
        ventureName: String,
        countryFormOptions: Object,
        industryFormOptions: Object,
        formDataObj: Object,
        businessValuationDataObj: Object,
        showEstimateResults: Boolean,
        hasSubmitError: Boolean,
        queryInProgress: Boolean,
    },
    emits: ['close:eq-modal', 'update:create-venture', 'update:submit-form', 'update:return-to-form'],
    data() {
        return {
            currentSection: 0,
            formSectionsArr: [
                {
                    "slug": "basics",
                    "titleStr": i18n.global.t('initial_estimate.heading.section_venture_basics'),
                    "status": "unstarted"
                },
                {
                    "slug": "stage",
                    "titleStr": i18n.global.t('initial_estimate.heading.section_venture_stage'),
                    "status": "unstarted"
                },
                {
                    "slug": "operations",
                    "titleStr": i18n.global.t('initial_estimate.heading.section_venture_operations'),
                    "status": "unstarted"
                }
            ],
            localFormData: {
                estimatedrevenues_y1: null,
                estimatedrevenues_y4: null,
                foundersnumber: null,
                employeesnumber: null,
                startedyear: null,
                country: null,
                industry: null,
                iptype: null,
                devstage: null,
                profitability: null,
                include_benchmark: true
            },
            intellectualPropertyOptions: [
                {
                    "value": "type1",
                    "labelTitleStr": i18n.global.t('initial_estimate.ip_type_options.type1_title')
                },
                {
                    "value": "type2",
                    "labelTitleStr": i18n.global.t('initial_estimate.ip_type_options.type2_title')
                },
                {
                    "value": "type3",
                    "labelTitleStr": i18n.global.t('initial_estimate.ip_type_options.type3_title')
                },
                {
                    "value": "type4",
                    "labelTitleStr": i18n.global.t('initial_estimate.ip_type_options.type4_title')
                },
                {
                    "value": "type5",
                    "labelTitleStr": i18n.global.t('initial_estimate.ip_type_options.type5_title')
                }
            ],
            profitabilityOptions: [
                {
                    "value": "type1",
                    "labelTitleStr": i18n.global.t('initial_estimate.profitability_options.type1_title')
                },
                {
                    "value": "type2",
                    "labelTitleStr": i18n.global.t('initial_estimate.profitability_options.type2_title')
                }
            ],
            devStageOptions: [
                {
                    "value": "type1",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type1_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type1_desc')
                },
                {
                    "value": "type2",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type2_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type2_desc')
                },
                {
                    "value": "type3",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type3_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type3_desc')
                },
                {
                    "value": "type4",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type4_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type4_desc')
                },
                {
                    "value": "type5",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type5_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type5_desc')
                },
                {
                    "value": "type6",
                    "labelTitleStr": i18n.global.t('initial_estimate.dev_stage_options.type6_title'),
                    "labelDescStr": i18n.global.t('initial_estimate.dev_stage_options.type6_desc')
                }
            ],
        }
    },
    methods: {
        async changeFormStep(direction) {
            if (direction === 'prev') {
                this.formSectionsArr[this.currentSection].status = "unstarted";
                this.currentSection--;
            }
            if (direction === 'next') {
                const isFormCorrect = await this.$refs[`initial-estimate-${this.currentSection}`].v$.$validate();
                if (isFormCorrect) {
                    this.formSectionsArr[this.currentSection].status = "complete";
                    
                    if (this.formHasNextStep) {
                        this.currentSection++;
                    } else {
                        this.submitForm();
                    }
                }
            }
        },
        convertObjToArr(obj) {
          return Object.keys(obj).map((item) => obj[item])
        },
        submitForm() {
            this.$emit('update:submit-form', this.localFormData)
        }
    },
    watch: {
        currentSection(newVal, oldValue) {
            
            // emit create venture event if user navigates from form step 1 (current section === 0)
            if (oldValue === 0) {
                this.$emit('update:create-venture')
            }
        }
    },
    computed: {
        formHasNextStep() {
            return this.currentSection < (this.formSectionsArr.length - 1);
        }
    },
    mounted() {
        this.localFormData = this.formDataObj;
    }
}
</script>
