const numberFormats = {
    en: {
        // Standard number with commas (e.g., 1,234.56)
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
        // Abbreviated numbers (e.g., 12.5k)
        short: {
            notation: 'compact',
            compactDisplay: 'short',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
    },
    es: {
        // Standard number with commas (e.g., 1.234,56)
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
        // Abbreviated numbers (e.g., 12,5 mil)
        short: {
            notation: 'compact',
            compactDisplay: 'short',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
    },
    fr: {
        // Standard number with commas (e.g., 1 234,56)
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
        // Abbreviated numbers (e.g., 12,5 k)
        short: {
            notation: 'compact',
            compactDisplay: 'short',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
    },
};

export default numberFormats;
