<template>
    <div class="vc4a-nav-bar-closable">
        <div class="container">
            <div class="vc4a-nav-bar-closable__content">
                <vc4a-logo class="vc4a-nav-bar-closable__logo" />
                <button
                    class="vc4a-btn vc4a-btn--icon"
                    @click="$emit('close:navBarClosable')"
                >
                    <x-mark-icon class="vc4a-icon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Vc4aLogo from '@stylesComponents/vue/vc4a-logo.vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';

export default {
    name: 'Vc4aNavBarClosable',
    components: {
        Vc4aLogo,
        XMarkIcon
    },
    emits: [
        // Emits a close event when the close button is clicked
        'close:navBarClosable'
    ]
}
</script>
