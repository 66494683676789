<!-- 
    Example of use-case (see /global-components/_popup-menu.scss for more possible elements)
     <popup-menu>
        <div class="popup-menu__title" :slot="0">Manage member</div> // section header
        <div :slot="1" class="popup-menu__action">View venture profile</div> // clickable item
        <div :slot="2" class="popup-menu__action">Send message</div>
        <hr :slot="3" class="my-0"> // divider
        <div :slot="4" class="popup-menu__action">View cohort</div>
    </popup-menu>
 -->

<template>
    <div class="popup-menu">
        <div
            class="btn btn--icon"
            @click.prevent="showMenu = !showMenu"
        >
            <vc4a-icon name="more-vertical" />
        </div>
        <div v-if="showMenu"
            class="popup-menu__actions-menu"
        >
            <div 
                v-for="(item, itemIndex) in $slots"
                :key="'item-' + itemIndex"
                class="popup-menu__item"
            >
                <slot :name="itemIndex"/>
            </div>
        </div>    
    </div>
</template>


<script>


import Vc4aIcon from '$globalsrc/common/vc4a-icon';

export default {
    name: "PopupMenu",
    components: {
        Vc4aIcon
    },
    props: {
    },
    data(){
        return {
            showMenu: false, 
        }
    },
    mounted() {
        const self = this;

        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.showMenu = false;
            }
        });
        window.addEventListener('click', (e) => {
            if (!self.$el.contains(e.target)){
              self.showMenu = false
            } 
        });
    },
}
</script>
